import React, { memo } from "react";
import CallButton from "./CallButton";

const MobileApp = () => {
    return (
        <>
            <section id="MobileApp">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex justify-content-center align-items-start flex-column">
                            <h2 className="heading">Integrated Mobile Application</h2>
                            <p className="desc mt-2">
                                Whether you are traveling to a different city or are a few miles from home, you can speak to a TruDoc doctor through our mobile application via voice/video call and live chat
                            </p>
                            <div className="mobileButton">
                                <a href="https://play.google.com/store/apps/details?id=com.youclick.wecare"><img src={process.env.PUBLIC_URL + "assets/img/playstore.svg"} alt="Play Store" title="Play Store" /></a>
                                <a href="https://apps.apple.com/us/app/you-click-we-care/id1015588412"><img src={process.env.PUBLIC_URL + "assets/img/apple.svg"} alt="Apple Store" title="Apple Store" /></a>
                            </div>
                            <CallButton url="/integratedmobileapp/1" content="Learn More" icon="" class="mt-Btn-Section padding-button" />
                        </div>
                        <div className="col-md-6">
                            <img src={process.env.PUBLIC_URL + "assets/img/mobile-app.png"} className="img-fluid" alt="Mobile App" title="Mobile App" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(MobileApp);