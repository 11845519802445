import React, { memo } from "react";

const WhatWeDo = (props) => {
    return (
        <>
            <section id="WhatWeDo">
                <div className="container">
                    <div class="row">
                        <div className="col-md-12">
                            <h2 class="heading">{props.heading}</h2>
                        </div>
                        <div className="col-md-12 mt-5">
                            <div className="row">
                                <div className="col-md-4 d-flex align-items-start">
                                    <div className="Card">
                                        <h4>Chronic care</h4>
                                        <h5>Patient Disease Management</h5>
                                        <h5>Hospitalist Program</h5>
                                        <h5>Hospital at Home Program</h5>
                                    </div>
                                </div>
                                <div className="col-md-4 d-flex align-items-center">
                                    <div className="Card">
                                        <h4>Acute care</h4>
                                        <h5>Telemedicine</h5>
                                        <h5>Home Care</h5>
                                    </div>
                                </div>
                                <div className="col-md-4 d-flex align-items-end">
                                    <div className="Card">
                                        <h4>Preventative care</h4>
                                        <h5>Nutrition Care</h5>
                                        <h5>Mental Health Care</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(WhatWeDo);