import React, { Component } from 'react'
import HttpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CreateSettingComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            name: '',
            value: '',
            description: '',
            rowVersion: ''
        }
        this.changeNameHandler = this.changeNameHandler.bind(this);
        this.saveOrUpdateSetting = this.saveOrUpdateSetting.bind(this);
    }

    componentDidMount() {

        if (this.state.id === '_add') {
            return
        } else {
            HttpHelper.getById('GetSettingById', this.state.id).then((res) => {
                let setting = res.data;
                this.setState({
                    name: setting.name,
                    value: setting.value,
                    description: setting.description,
                    rowVersion: setting.rowVersion
                });
            });
        }
    }

    saveOrUpdateSetting = (e) => {
        e.preventDefault();
        let setting = {
            name: this.state.name,
            value: this.state.value,
            description: this.state.description,
            rowVersion: this.state.rowVersion
        };
        /*console.log('setting => ' + JSON.stringify(setting));*/

        // step 5
        if (this.state.id === '_add') {
            HttpHelper.create('/PostSetting', setting).then(res => {
                toast("Saved successfully!");
                this.props.history.push('/setting');
                /*setInterval(() => { this.props.history.push('/setting'); }, 1000);                */
            });
        } else {
            HttpHelper.update('UpdateSetting', setting, this.state.id).then(res => {
                toast("Saved successfully!");
                this.props.history.push('/setting');
                /*setInterval(() => { this.props.history.push('/setting'); }, 1000);*/
            });
        }
    }

    changeNameHandler = (event) => {
        this.setState({ name: event.target.value });
    }

    changeValueHandler = (event) => {
        this.setState({ value: event.target.value });
    }


    changeDescriptionHandler = (event) => {
        this.setState({ description: event.target.value });
    }



    cancel() {
        this.props.history.push('/setting');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add Setting</h3>
        } else {
            return <h3 className="text-center">Update Setting</h3>
        }
    }


    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Setting</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Name: </label>
                                                            <input placeholder="Name" name="name" className="form-control"
                                                                value={this.state.name} onChange={this.changeNameHandler} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Value: </label>
                                                            <input placeholder="Value" name="value" className="form-control"
                                                                value={this.state.value} onChange={this.changeValueHandler} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Description: </label>
                                                            <input placeholder="Description" name="description" className="form-control"
                                                                value={this.state.description} onChange={this.changeDescriptionHandler} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-primary btn-sm" onClick={this.saveOrUpdateSetting} style={{ marginTop: "30px" }}>Save</button>
                                                        {/*<button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>*/}
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default CreateSettingComponent