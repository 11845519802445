import React, { Component } from 'react'
import httpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



class OurPresenceCreateComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            title: '',
            country: '',
            phoneno: '',
            email: '',
            contactperson: '',
            islive: '',
            longitude: '',
            latitude: '',
            CountryFlag: ''
        }
        //this.changeTitleHandler = this.changeTitleHandler.bind(this);
        this.saveOrUpdateOurPresence = this.saveOrUpdateOurPresence.bind(this);
    }

    componentDidMount() {
        debugger;
        if (this.state.id === '_add') {
            this.state.islive = true;
            return
        } else {
            httpHelper.getById('GetOurPresenceById', this.state.id).then((res) => {
                let home = res.data;
                this.setState({
                    title: home.title,
                    country: home.country,
                    phoneno: home.phoneNo,
                    email: home.email,
                    contactperson: home.contactPerson,
                    islive: home.isLive,
                    longitude: home.longitude,
                    latitude: home.latitude,
                    ourpresenceid: home.ourpresenceid,
                    countryFlag: home.countryFlag,
                });
            });
        }
    }

    saveOrUpdateOurPresence = (e) => {
        e.preventDefault();
        let ourpresence = {
            title: this.state.title,
            country: this.state.country,
            phoneno: this.state.phoneno,
            email: this.state.email,
            contactperson: this.state.contactperson,
            islive: this.state.islive == "on" ? true : false,
            longitude: this.state.longitude,
            latitude: this.state.latitude,
            ourpresenceid: parseInt(this.state.ourpresenceid),
            countryFlag: this.state.countryFlag,
        };

        // step 5
        if (this.state.id === '_add') {
            httpHelper.create('/AddOurPresence', ourpresence).then(res => {
                toast("Saved successfully!");
                this.props.history.push('/ourpresence-list');
                /*setInterval(() => { this.props.history.push('/ourpresence-list'); }, 1000);               */
            });
        } else {
            ourpresence.id = this.state.id;
            httpHelper.update('UpdateOurPresence', ourpresence, this.state.id).then(res => {
                toast("Saved successfully!");
                this.props.history.push('/ourpresence-list');
                /*setInterval(() => { this.props.history.push('/ourpresence-list'); }, 1000);*/
            });
        }
    }

    changeAttachmentsHandler = (event) => {
        const formData = new FormData();
        const filePath = "Upload\\OurPresence";
        formData.append("fileName", event.target.files[0].name);
        formData.append("formFile", event.target.files[0]);
        formData.append("filePath", filePath);

        try {
            const res = httpHelper.upload("CommonFileUpload", formData);
        } catch (ex) {
        }
        this.setState({ bannerImage: event.target.files[0].name });
    }

    cancel() {
        this.props.history.push('/ourpresence-list');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add OurPresence</h3>
        } else {
            return <h3 className="text-center">Update OurPresence</h3>
        }
    }

    changeTitleHandler = (event) => {
        this.setState({ title: event.target.value });
    }
    changeCountryHandler = (event) => {
        this.setState({ country: event.target.value });
    }
    changePhonenoHandler = (event) => {
        this.setState({ phoneno: event.target.value });
    }
    changeEmailHandler = (event) => {
        this.setState({ email: event.target.value });
    }
    changeContactpersonHandler = (event) => {
        this.setState({ contactperson: event.target.value });
    }
    changeIsliveHandler = (event) => {       
        this.setState({ islive: event.target.checked });
    }
    changeLongitudeHandler = (event) => {
        this.setState({ longitude: event.target.value });
    }
    changeLatitudeHandler = (event) => {
        this.setState({ latitude: event.target.value });
    }
    changeOurPresenceIdHandler = (event) => {
        this.setState({ ourpresenceid: event.target.value });
    }

    changeCountryFlagHandler = (event) => {
        const formData = new FormData();
        const filePath = "Upload\\OurPresence";
        formData.append("fileName", event.target.files[0].name);
        formData.append("formFile", event.target.files[0]);
        formData.append("filePath", filePath);
        try {
            const res = httpHelper.upload("CommonFileUpload", formData);
        } catch (ex) {
        }
        this.setState({ countryFlag: event.target.files[0].name });
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Our Presence</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control"
                                                                value={this.state.title} onChange={this.changeTitleHandler} required="required" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Country: </label>
                                                            <input placeholder="Country" name="country" className="form-control"
                                                                value={this.state.country} onChange={this.changeCountryHandler} required="required" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Phone no: </label>
                                                            <input placeholder="Phone no" name="phoneno" className="form-control"
                                                                value={this.state.phoneno} onChange={this.changePhonenoHandler} required="required" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Email: </label>
                                                            <input placeholder="Email" name="email" className="form-control"
                                                                value={this.state.email} onChange={this.changeEmailHandler} required="required" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Contact person: </label>
                                                            <input placeholder="Contact person" name="contactperson" className="form-control"
                                                                value={this.state.contactperson} onChange={this.changeContactpersonHandler} required="required" />
                                                        </div>

                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Is live: </label>
                                                            <input type="checkbox" name="islive" className="form-control form-check-input" checked={this.state.islive} value={this.state.islive} onChange={this.changeIsliveHandler} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Longitude: </label>
                                                            <input placeholder="Longitude" name="longitude" className="form-control"
                                                                value={this.state.longitude} onChange={this.changeLongitudeHandler} required="required" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Latitude: </label>
                                                            <input placeholder="Latitude" name="latitude" className="form-control"
                                                                value={this.state.latitude} onChange={this.changeLatitudeHandler} required="required" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Country Flag: </label>
                                                            <input type="file" placeholder="CountryFlag" name="countryFlag" className="form-control"
                                                                onChange={this.changeCountryFlagHandler} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <button className="btn btn-primary btn-sm" onClick={this.saveOrUpdateOurPresence} style={{ marginTop: "30px" }}>Save</button>
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default OurPresenceCreateComponent