import React, { Component } from 'react'
import httpHelper from '../../../../httpHelper';
import DashboardHeader from '../../../Dashboard/DashboardHeader';
import Sidebar from '../../../Dashboard/Sidebar';
import FileBase64 from 'react-file-base64';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CreateServiceServicesComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            serviceId: '',
            service: [],
            title: '',
            serviceIcon: '',
            errors: {}

        }
        this.changeTitleHandler = this.changeTitleHandler.bind(this);
        this.saveOrUpdateServiceServices = this.saveOrUpdateServiceServices.bind(this);
    }
    handleValidation(data) {
        let errors = {};
        let formIsValid = true;

        if (data.serviceId == "") {
            formIsValid = false;
            errors["serviceId"] = "Cannot be empty";
        }

        if (data.title == "") {
            formIsValid = false;
            errors["title"] = "Cannot be empty";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    componentDidMount() {

        if (this.state.id === '_add') {
            httpHelper.get('GetListDropDownServices').then(response => {
                console.log(response.data);
                this.setState({
                    service: response.data
                });
            });
            return
        } else {
            httpHelper.get('GetListDropDownServices').then(response => {
                console.log(response.data);
                this.setState({
                    service: response.data
                });
            });
            httpHelper.getById('GetServiceServicesById', this.state.id).then((res) => {
                let serviceServices = res.data;
                this.setState({
                    serviceId: serviceServices.serviceId,
                    title: serviceServices.title,
                    serviceIcon: serviceServices.serviceIcon,
                });
            });
        }
    }

    saveOrUpdateServiceServices = (e) => {
        e.preventDefault();
        let serviceServices = {
            serviceId: this.state.serviceId,
            title: this.state.title,
            serviceIcon: this.state.serviceIcon
        };

        // step 5
        if (this.state.id === '_add') {
            if (this.handleValidation(serviceServices)) {
                httpHelper.create('/PostServiceServices', serviceServices).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/serviceServices');
                    /*setInterval(() => { this.props.history.push('/serviceServices'); }, 1000);                    */
                });
            }
        } else {
            if (this.handleValidation(serviceServices)) {
                httpHelper.update('UpdateServiceServices', serviceServices, this.state.id).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/serviceServices');
                    /*setInterval(() => { this.props.history.push('/serviceServices'); }, 1000);*/
                });
            }
        }
    }

    changeServiceIdHandler = (event) => {
        this.setState({ serviceId: event.target.value });
    }

    changeTitleHandler = (event) => {
        this.setState({ title: event.target.value });
    }

    //changeServiceIconHandler = (event) => {

    //    const formData = new FormData();
    //    const filePath = "Upload\\Service\\Services\\Icon";
    //    formData.append("fileName", event.target.files[0].name);
    //    formData.append("formFile", event.target.files[0]);
    //    formData.append("filePath", filePath);
    //    try {
    //        const res = httpHelper.upload("CommonFileUpload", formData);
    //    } catch (ex) {
    //    }
    //    this.setState({ serviceIcon: event.target.files[0].name });

    //}

    getFiles(files) {
        this.setState({ serviceIcon: files.base64 })
    }

    cancel() {
        this.props.history.push('/serviceServices');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add ServiceServices</h3>
        } else {
            return <h3 className="text-center">Update ServiceServices</h3>
        }
    }


    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Service Services</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Description: </label>
                                                            <select className="form-control" name="serviceId" value={this.state.serviceId} onChange={this.changeServiceIdHandler}  >
                                                                <option>Select Service</option>
                                                                {this.state.service.map((e, key) => {
                                                                    return <option key={key} value={e.dataValue}>{e.dataText}</option>;
                                                                })}
                                                            </select>
                                                            <span style={{ color: "red" }}>{this.state.errors["serviceId"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control"
                                                                value={this.state.title} onChange={this.changeTitleHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["title"]}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <img width='20' height='20' src={this.state.serviceIcon} />
                                                        </div>
                                                        <div className="form-group">
                                                            {/* <label> Service Icon: </label>*/}
                                                            {/*<input type="file" placeholder="Service Icon" name="serviceIcon" className="form-control"*/}
                                                            {/*    onChange={this.changeServiceIconHandler} />*/}
                                                            <FileBase64 placeholder="ServiceIcon" name="serviceIcon" className="form-control"
                                                                multiple={false} value={this.state.serviceIcon}
                                                                onDone={this.getFiles.bind(this)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-primary btn-sm" onClick={this.saveOrUpdateServiceServices} style={{ marginTop: "30px" }}>Save</button>
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default CreateServiceServicesComponent