import React, { Component } from 'react'
import HttpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from 'suneditor-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CreateTestimonialsComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            title: '',
            description: '',
            content: '',
            name: '',
            age: '',
            isActive: false,
            errors: {}
        }
        this.changeTitleHandler = this.changeTitleHandler.bind(this);
        this.saveOrUpdateTestimonials = this.saveOrUpdateTestimonials.bind(this);
    }
    handleValidation(data) {
        let errors = {};
        let formIsValid = true;

        if (data.title == "") {
            formIsValid = false;
            errors["title"] = "Cannot be empty";
        }

        if (data.content == "") {
            formIsValid = false;
            errors["content"] = "Cannot be empty";
        } else if (data.content.length > 275) {
            formIsValid = false;
            errors["content"] = "length of text must be less than 275.";
        }

        if (data.name == "") {
            formIsValid = false;
            errors["name"] = "Cannot be empty";
        }

        if (data.age == "") {
            formIsValid = false;
            errors["age"] = "Cannot be empty";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    componentDidMount() {

        if (this.state.id === '_add') {
            return
        } else {
            HttpHelper.getById('GetTestimonialsById', this.state.id).then((res) => {
                let testimonials = res.data;
                this.setState({
                    title: testimonials.title,
                    description: testimonials.description,
                    content: testimonials.content,
                    name: testimonials.name,
                    age: testimonials.age,
                    isActive: testimonials.isActive
                });
            });
        }
    }

    saveOrUpdateTestimonials = (e) => {
        e.preventDefault();
        let testimonials = {
            title: this.state.title,
            description: this.state.description,
            content: this.state.content,
            name: this.state.name,
            age: this.state.age,
            isActive: this.state.isActive
        };

        // step 5
        if (this.state.id === '_add') {
            if (this.handleValidation(testimonials)) {
                HttpHelper.create('/PostTestimonials', testimonials).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/testimonials');
                    /*setInterval(() => { this.props.history.push('/testimonials'); }, 1000);*/
                });
            }

        } else {
            if (this.handleValidation(testimonials)) {
                HttpHelper.update('UpdateTestimonials', testimonials, this.state.id).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/testimonials');
                    /*setInterval(() => { this.props.history.push('/testimonials'); }, 1000);*/
                });
            }
        }
    }

    changeTitleHandler = (event) => {


        this.setState({ title: event.target.value });

    }

    changeDescriptionHandler = (event) => {
        this.setState({ description: event.target.value });
    }

    changeContentHandler = (event) => {

        this.setState({ content: event });

    }

    changeNameHandler = (event) => {

        this.setState({ name: event.target.value });

    }

    changeAgeHandler = (event) => {

        this.setState({ age: event.target.value });

    }

    changeIsActiveHandler = (event) => {

        this.setState({ isActive: event.target.checked });

    }

    cancel() {
        this.props.history.push('/testimonials');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add Testimonials</h3>
        } else {
            return <h3 className="text-center">Update Testimonials</h3>
        }
    }



    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Testimonials</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control"
                                                                value={this.state.title} onChange={this.changeTitleHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["title"]}</span>

                                                        </div>
                                                        <div className="form-group">
                                                            <label> Name: </label>
                                                            <input placeholder="Name" name="name" className="form-control"
                                                                value={this.state.name} onChange={this.changeNameHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["name"]}</span>

                                                        </div>
                                                        <div className="form-group">
                                                            <label> Age: </label>
                                                            <input type="number" className="form-control" name="age" placeholder="Age"
                                                                value={this.state.age} onChange={this.changeAgeHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["age"]}</span>

                                                        </div>
                                                        <div className="form-check form-check-flat form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" name="isActive" className="form-check-input"
                                                                    checked={this.state.isActive}
                                                                    onChange={this.changeIsActiveHandler} />
                                                                Active
                                                                <i className="input-helper"></i></label>

                                                        </div>
                                                    </div>
                                                    {/*<div className="col-md-6">*/}
                                                    {/*    <div className="form-group">*/}
                                                    {/*        <label> Description: </label>*/}
                                                    {/*        <input placeholder="Description" name="description" className="form-control"*/}
                                                    {/*            value={this.state.description} onChange={this.changeDescriptionHandler} />*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Content: </label>
                                                            <SunEditor name="content" height="400" placeholder="Content" setOptions={{
                                                                buttonList: [
                                                                    /*['undo', 'redo', 'font', 'fontSize', 'formatBlock'],*/
                                                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                                    /*'/', //Line break*/
                                                                    ['hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                                    ['fontColor','fontSize', 'font'],
                                                                    /*['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']*/
                                                                ],
                                                            }}
                                                                setContents={this.state.content} defaultValue={this.state.content} onChange={this.changeContentHandler} />

                                                            {/*<input placeholder="Content" name="content" className="form-control"*/}
                                                            {/*    value={this.state.content} onChange={this.changeContentHandler} />*/}
                                                            <span style={{ color: "red" }}>{this.state.errors["content"]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-primary btn-sm" onClick={this.saveOrUpdateTestimonials} style={{ marginTop: "30px" }}>Save</button>
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default CreateTestimonialsComponent