import axios from 'axios';
import { initConfig } from './Config'


//Sandeep Sirohi: Build configuration.
const config = initConfig();

var baseUri = "https://localhost:7053/Admin";
//Sandeep Sirohi: Check environment.
if (process.env.NODE_ENV !== "development")
    baseUri = `${window.location.origin}/Admin`

//Sandeep Sirohi: Http helper class.
class httpHelper {

    constructor() {
        //Sandeep Sirohi: Create axios instance.
        this.instance = axios.create({
            timeout: 1000 * 60 * 5,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            maxRedirects: 5,
        });
    }

    //Sandeep Sirohi: Logger.
    log(message) {
        console.log('%c HTTP:' + message, 'background: #222; color: #bada55');
    }

    //Sandeep Sirohi: Add authorization in request header.
    addAuthorization(message) {

        var accessToken = sessionStorage.getItem(`msal.${config.clientId}.idtoken`);
        this.instance.interceptors.request.use(config => {
            config.headers = {
                ...config.headers,
                'Authorization': `Bearer ${accessToken}`
            }
            return config;
        });
    }

    //Sandeep Sirohi: helper methods
    get(uri) {
        //this.addAuthorization('get-' + uri);
        return this.instance.get(baseUri + '/' + uri, { headers: { Authorization: `Bearer ${sessionStorage.getItem(`msal.${config.clientId}.idtoken`)}` } });
    }

    create(uri, model) {
        //this.addAuthorization('create-' + uri);
        return this.instance.post(baseUri + uri, model, { headers: { Authorization: `Bearer ${sessionStorage.getItem(`msal.${config.clientId}.idtoken`)}` } });
    }

    getById(uri, id) {
        //this.addAuthorization('create-' + uri);
        return this.instance.get(baseUri + '/' + uri + '/' + id, { headers: { Authorization: `Bearer ${sessionStorage.getItem(`msal.${config.clientId}.idtoken`)}` } });
    }

    update(uri, model, id) {
        // this.addAuthorization('update-' + uri);
        return this.instance.put(baseUri + '/' + uri + '/' + id, model, { headers: { Authorization: `Bearer ${sessionStorage.getItem(`msal.${config.clientId}.idtoken`)}` } });
    }

    delete(uri, id) {
        // this.addAuthorization('delete-' + uri);
        return this.instance.delete(baseUri + '/' + uri + '/' + id, { headers: { Authorization: `Bearer ${sessionStorage.getItem(`msal.${config.clientId}.idtoken`)}` } });
    }

    upload(uri, model) {
        //this.addAuthorization('update-' + uri);
        return this.instance.post(baseUri + '/' + uri, model, { headers: { Authorization: `Bearer ${sessionStorage.getItem(`msal.${config.clientId}.idtoken`)}` } });
    }

    getBySearch(uri, id, search) {
        return this.instance.get(baseUri + '/' + uri + '/' + id + '/' + search, { headers: { Authorization: `Bearer ${sessionStorage.getItem(`msal.${config.clientId}.idtoken`)}` } });
    }
    isOrdinalValid(uri,ordinalValue) {
        return this.instance.get(baseUri + '/' + uri + '/' + ordinalValue, { headers: { Authorization: `Bearer ${sessionStorage.getItem(`msal.${config.clientId}.idtoken`)}` } });
    }

}

export default new httpHelper()