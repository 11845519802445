import React, { memo, Component } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import httpHelper from "../httpHelper";

class Features_WWD extends Component {
    constructor(props) {
        super(props);

        console.log(this.props.service_id);
        this.state = {
            id: this.props.service_id,
            features: []
        }
     
        httpHelper.getById('GetListServiceFeaturesByServiceId', this.state.id).then(res => {
            console.log("Featues-",res.data);
            this.setState({ features: res.data });
        });
    }



    render() {
        return (
            <>
                <section id="Features_WWD" className="lessPadding50 wbg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="heading heading-text-thm-blue ">
                                    Features
                                </h2>
                            </div>
                           
                            <div className="col-md-12 mt-5">
                                            <ul className="truoc_ul row">
                                                {
                                                    this.state.features.map(
                                                        features =>
                                                            <li className="fs-20 col-md-6"><img src={process.env.PUBLIC_URL + "assets/img/tick_blue.png"} alt="Blue tick" title="Blue tick" className="li_tick" />{features.title}</li>
                                      )}
                                                        </ul>
                            </div>
                           

                        </div>
                    </div>
            </section>
            </>
        )
    }
}

export default memo(Features_WWD);