import React, { memo } from 'react';
import { HiPhone } from "react-icons/hi";

const CallButton = (props) => {
    return (
        <>
            {props.LinkTag === 'No' ? <button className={`${props.bgColor === 'white' ? 'callButton' : 'callButtonThemeColor'} ${props.class} btn `} style={{ cursor: props.LinkTag === 'No' ? 'default' : 'pointer' }} >
                {props.icon === '' ? '' : <HiPhone />}  <span>{props.content}</span>
            </button> : <a href={props.url === '' ? '' : props.url} target={props.target === 'blank' ? '_blank' : ''} >
                <button className={`${props.bgColor === 'white' ? 'callButton' : 'callButtonThemeColor'} ${props.class} btn `}>
                    {props.icon === '' ? '' : <HiPhone />}  <span>{props.content}</span>
                </button>
            </a>}

        </>

    )
}
export default memo(CallButton);