import React, { memo, Component } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import httpHelper from "../httpHelper";

class Benefits_WWD extends Component {
    constructor(props) {
        super(props);

        console.log(this.props);
        this.state = {
            id: this.props.service_id,
            benefits: [],
            benefitsType: []
        }

        httpHelper.get('GetListDropDownBenefits').then(res => {
            console.log("Benefits-", res.data);
            this.setState({ benefitsType: res.data });
        });

        httpHelper.getById('GetListServiceBenefitsByServiceId', this.state.id).then(res => {
            console.log("Benefits-", res.data);
            this.setState({ benefits: res.data });
        });
    }



    render() {
        return (
            <>
                <section id="Benefits_WWD" className="lessPadding50">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-5">
                                <h2 className="heading">
                                    Benefits:
                                </h2>
                            </div>

                            {
                                this.state.benefitsType.map(
                                    type => {
                                        /*if (benefits.benefitsTypeId == "forindividuals") {*/
                                        return (
                                            <>
                                                <div className="col-md-12">
                                                    <h2 className="sub_heading">
                                                        {type.dataText}:
                                                    </h2>
                                                </div>
                                                <hr className="custom_hr mb-4" />
                                                <div className="col-md-12" key={type.dataValue}>
                                                    <ul className="truoc_ul row" >
                                                        {
                                                            this.state.benefits.map(
                                                                benefits1 => {
                                                                    if (benefits1.benefitsTypeId == type.dataValue) {
                                                                        return (
                                                                            <>
                                                                                <li className="fs-20 text-grey col-md-6"><img src={process.env.PUBLIC_URL + "assets/img/tick.png"} alt="Tick" title="Tick" className="li_tick" />{benefits1.title}</li>
                                                                            </>
                                                                        )
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </>
                                        )
                                        /*}*/
                                    }
                                )
                            }

                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default memo(Benefits_WWD);