import React, { Component } from 'react'
import SunEditor from 'suneditor/src/lib/core';
import { disable } from 'workbox-navigation-preload';
import httpHelper from '../../../../httpHelper';
import DashboardHeader from '../../../Dashboard/DashboardHeader';
import Sidebar from '../../../Dashboard/Sidebar';


class ViewServiceFeaturesComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            services: [],
            serviceFeatures: {}
        }
    }

    componentDidMount() {
        httpHelper.get('GetListDropDownServices').then(response => {
            console.log(response.data);
            this.setState({
                services: response.data
            });
        });

        httpHelper.getById('GetServiceFeaturesById', this.state.id).then(res => {
            this.setState({ serviceFeatures: res.data });
        })
    }

    cancel() {
        this.props.history.push('/serviceFeatures');
    }

    render() {
        return (

            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Service Features</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Description: </label>
                                                            <select className="form-control" name="serviceId" value={this.state.serviceFeatures.serviceId} disabled="true" >
                                                                <option>Select Service</option>
                                                                {this.state.services.map((e, key) => {
                                                                    return <option key={key} value={e.dataValue}>{e.dataText}</option>;
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control" readOnly={true}
                                                                value={this.state.serviceFeatures.title} />
                                                            {/*<SunEditor name="content" height="400" placeholder="Content", */}
                                                            {/*setOptions={{*/}
                                                            {/*    buttonList: [*/}
                                                            {/*        /*['undo', 'redo', 'font', 'fontSize', 'formatBlock'],*/}
                                                            {/*        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],*/}
                                                            {/*        /*'/', //Line break*/}
                                                            {/*        ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],*/}
                                                            {/*        /*['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']*/}
                                                            {/*    ],*/}
                                                            {/*}}*/}
                                                            {/*    setContents={this.state.serviceFeatures.title} defaultValue={this.state.serviceFeatures.title} disable={true} />*/}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Back</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

export default ViewServiceFeaturesComponent