import React, { memo } from "react";


const SubServiceOffered = (props) => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row mb-5 mt-5">
                        <div className="col-md-12">
                            <h2 className="text-thm-blue mb-4">Challenges we help with:</h2>
                        </div>
                        <div className="col-md-6">
                            <ul className="truoc_ul ">
                                <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick"className="li_tick" />Life transitions</li>
                                <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Emotional problems</li>
                                
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <ul className="truoc_ul ">
                                <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Behavioral issues</li>
                                <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Workplace mental health problems</li>
                               
                            </ul>
                        </div>

                    </div>
                </div>
            </section>
            <section id="SubServiceOffered">
                <div className="">
                    <div className="row align-center">
                        <div className="col-md-6 ">
                            <div className="padd135">
                                <h2 className="text-white  mb-4">Other services offered:</h2>
                                <ul className="truoc_ul text-white">
                                    <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />On-going counseling and follow-up care</li>
                                    <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Access to mental-health-related resources</li>
                                    <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Referrals and appointment booking, if required</li>
                                    <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Multidisciplinary care including doctor
                                        consultations and nutritional care via voice
                                        and video call</li>
                                    

                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={process.env.PUBLIC_URL + props.right_image1} alt="Sub Service" title="Sub Service" className="w-100 h644" />
                        </div>
                    </div>


                </div>
            </section>
            <section>
                <div className="container">
                <div className="row">
                        <div className="col-md-12 mb-5 mt-5">
                        <p className="offerservice1btm mb-0">
                            *Your privacy will be respected and confidentiality will be maintained at all times
                        </p>
                    </div>
                    </div>
                    </div>
                </section>
        </>
    );
}

export default memo(SubServiceOffered);