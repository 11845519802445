import React, { memo } from "react";

const OurPartners = (props) => {
    return (
        <>
            <section id="OurPartners">
                <div className="container">
                    <div class="row">
                        <div className="col-md-12">
                            <h3 className="heading">
                                {props.heading}
                            </h3>
                        </div>
                        <div className="col-md-12 mt-3">
                            <div className="row">
                                <div className="col-md-3 imgOurClients">
                                    <div><img src={process.env.PUBLIC_URL + "assets/img/our-partners/roche.png"} className="img-fluid" alt="Roche" title="Roche" /></div>
                                    <h6 className="headingPartners">Diabetes Management Program</h6>
                                </div>
                                <div className="col-md-3 imgOurClients">
                                    <div><img src={process.env.PUBLIC_URL + "assets/img/our-partners/microsoft.png"} className="img-fluid" alt="Microsoft" title="microsoft" /></div>
                                    <h6 className="headingPartners">Technology partner</h6>
                                </div>
                                <div className="col-md-3 imgOurClients">
                                    <div><img src={process.env.PUBLIC_URL + "assets/img/our-partners/glo.png"} className="img-fluid" alt="Glo" title="Glo" /></div>
                                    <h6 className="headingPartners">Truhealth services through telecom</h6>
                                </div>
                                <div className="col-md-3 imgOurClients">
                                    <div><img src={process.env.PUBLIC_URL + "assets/img/our-partners/advantage.png"} className="img-fluid" alt="Advantage" title="Advantage" /></div>
                                    <h6 className="headingPartners">Teleconsultation through physical pharmacy infrastructure</h6>
                                </div>
                                <div className="col-md-3 imgOurClients">
                                    <div><img src={process.env.PUBLIC_URL + "assets/img/our-partners/lux.png"} className="img-fluid" alt="Lux" title="Lux" /></div>
                                    <h6 className="headingPartners">Actuarial Risk Assestment</h6>
                                </div>
                                <div className="col-md-3 imgOurClients">
                                    <div><img src={process.env.PUBLIC_URL + "assets/img/our-partners/menalabs.png"} className="img-fluid" alt="Mena Labs" title="Mena Labs" /></div>
                                    <h6 className="headingPartners">Lab Partners</h6>
                                </div>
                                <div className="col-md-3 imgOurClients">
                                    <div><img src={process.env.PUBLIC_URL + "assets/img/our-partners/justice.png"} className="img-fluid" alt="Justice" title="Justice" /></div>
                                    <h6 className="headingPartners">Providing telemedicine services to the residents of lagos</h6>
                                </div>

                                <div className="col-md-3 imgOurClients">
                                    <div><img src={process.env.PUBLIC_URL + "assets/img/our-partners/Dubai.png"} className="img-fluid" alt="Dubai" title="Dubai" /></div>
                                    <h6 className="headingPartners">Hospital at Home, HCV & Cancer PSP Program & Doctor for Every Citizen </h6>
                                </div>

                                <div className="col-md-3 imgOurClients">
                                    <div><img src={process.env.PUBLIC_URL + "assets/img/our-partners/now.png"} className="img-fluid" alt="Now" title="Now" /></div>
                                    <h6 className="headingPartners">Services integration with mobile money application</h6>
                                </div>

                                <div className="col-md-3 imgOurClients">
                                    <div><img src={process.env.PUBLIC_URL + "assets/img/our-partners/forward.png"} className="img-fluid" alt="Forward" title="Forward" /></div>
                                    <h6 className="headingPartners">Digital healthcare solution to contain medical loss ratio</h6>
                                </div>

                                <div className="col-md-3 imgOurClients">
                                    <div>
                                        <img src={process.env.PUBLIC_URL + "assets/img/our-partners/yod.png"} className="img-fluid" alt="Yod" title="Yod" />
                                    </div>

                                    <h6 className="headingPartners">API Integrated for medicatoin delivery</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(OurPartners);