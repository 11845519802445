import { React, memo } from "react";

const ReviewUser = (props) => {
    return (
        <>
            <section id="ReviewUser">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <h2 className="text-ThemeTwo heading-Section">{props.heading}</h2>
                        </div>
                        <div className="col-lg-2 col-md-4 ReviewUserContentResImg">
                            <img src={process.env.PUBLIC_URL + props.imgReview} alt="Albert Einstein" title="Albert Einstein" className="imgReview" />
                        </div>
                        <div className="col-lg-10 col-md-8 d-flex flex-column justify-content-center align-items-center ReviewUserContentRes ">
                            <h3 className="detailContent  position-relative">
                                <img src={process.env.PUBLIC_URL + 'assets/img/top-block-light.png'} alt="Blockquote" title="Blockquote" className="DetailsBoxBlock" />
                                <p className="detailContentPara"> The definition of insanity is doing the same thing over and over and expecting different results.</p>
                                <img src={process.env.PUBLIC_URL + 'assets/img/block-light.png'} alt="Blockquote" title="Blockquote" className="DetailsBoxBlockBottom" />
                            </h3>
                            <h2 className="float-right align-self-end subDesc">- Albert Einstein</h2>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-4 mb-4">
                                <div className="DetailsBox">
                                    Misalignment between patients, payers and providers
                                </div>
                            </div>

                            <div className="col-md-4 mb-4">
                                <div className="DetailsBox">
                                    Fragmented and ineffective single disease management programs
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="DetailsBox">
                                    Lack of stratification hence inappropriate level of care according to Evidence-Based Medicine
                                </div>
                            </div>
                            
                            <div className="col-md-4 mb-4">
                                <div className="DetailsBox">
                                    Unnecessarily hospital average bed days
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="DetailsBox">
                                    Unnecessarily readmission rate to the hospital
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="DetailsBox">
                                    Imbalanced supply and demand hence overutilization or underutilization
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="DetailsBox">
                                    Lack of early intervention and preventive care
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="DetailsBox">
                                    Lack of 24x7 access to doctors
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="DetailsBox">
                                    Lack of effective delivery system for high-risk patients
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(ReviewUser);