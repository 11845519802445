import { React } from "react";
import CareerComponent from "../components/CareerComponent";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import PageContent from "../components/PageContent";
import PageTitle from "../components/PageTitle";

const CareerPage = (props) => {
  return (
    <>
      <Header transparent={false} />
      <PageTitle title="Careers" bgImage="assets/img/Career-page.png" />
      <PageContent class="">
        Established in 2011, TruDoc started off with only 8 clients. Today, we
        are trusted by over 6,700 multinational clients with 1.1 million
        subscribers.
        <br />
        <br />
        As our organization grows, we are looking for passionate individiuals to
        grow alongside us.
      </PageContent>

      <PageContent class="bg-white">
        <CareerComponent />
      </PageContent>
      <Footer />
    </>
  );
};
export default CareerPage;
