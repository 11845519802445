import React, { Component } from 'react';
import httpHelper from '../../../httpHelper';
import DashboardHeader from '../../Dashboard/DashboardHeader';
import Sidebar from '../../Dashboard/Sidebar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ListServiceComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            services: []
        }
        this.addService = this.addService.bind(this);
        this.editService = this.editService.bind(this);
        this.deleteService = this.deleteService.bind(this);
    }

    deleteService(id) {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => httpHelper.delete('DeleteServices', id).then(res => {
                        this.setState({ services: this.state.services.filter(service => service.serviceId !== id) });
                        toast("Record delete successfully!");
                        this.props.history.push('/service');
                        /*setInterval(() => { this.props.history.go('/service'); }, 1000);*/
                    })
                },
                {
                    label: 'No',
                    onClick: () => { this.props.history.push('/service'); }
                }
            ]
        });
    }
    viewService(id) {
        this.props.history.push(`/view-service/${id}`);
    }
    editService(id) {

        this.props.history.push(`/add-service/${id}`);
    }

    componentDidMount() {
        httpHelper.get('GetListServices').then((res) => {
            this.setState({ services: res.data });
        });
    }

    addService() {
        this.props.history.push('/add-service/_add');
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Service</h4>
                                            <div className="row">
                                                <div className="col-md-10">
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-primary btn-sm" style={{ float: "right" }} onClick={this.addService}> Add </button>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="row">
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-bordered ">

                                                        <thead>
                                                            <tr>
                                                                <th> Title </th>
                                                                <th> Description </th>
                                                                <th> Video </th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.services.map(
                                                                    service =>
                                                                        <tr key={service.serviceId}>
                                                                            <td> {service.title} </td>
                                                                            <td> {service.description}</td>
                                                                            <td> {service.isVideo === true ? 'Yes' : 'No'}</td>
                                                                            <td style={{ textAlign: "right" }}>
                                                                                <button onClick={() => this.editService(service.serviceId)} className="btn btn-primary btn-sm">Update </button>
                                                                                <button style={{ marginLeft: "10px" }} onClick={() => this.deleteService(service.serviceId)} className="btn btn-danger btn-sm">Delete </button>
                                                                                <button style={{ marginLeft: "10px" }} onClick={() => this.viewService(service.serviceId)} className="btn btn-primary btn-sm">View </button>
                                                                            </td>
                                                                        </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default ListServiceComponent