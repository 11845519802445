import React, { Component } from 'react'
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import PageTitle from "../components/PageTitle";
import WhatWeDoContent from "../components/WhatWeDoContent";
import Service_WWD from "../components/Service_WWD.js";
import Features_WWD from "../components/Features_WWD.js";
import Benefits_WWD from "../components/Benefits_WWD.js";
import httpHelper from '../httpHelper';
import { Link } from "react-router-dom";
import { MdFollowTheSigns, MdOutlineKeyboardArrowRight } from "react-icons/md";
import ReactHtmlParser from 'react-html-parser';

var pageurl = "https://localhost:44417";
//SS: Check environment.
if (process.env.NODE_ENV !== "development")
pageurl = `${window.location.origin}`
class IntegratedMobileApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            service: [],
            services: [],
        }

        httpHelper.getById('GetServicesById', this.state.id).then(res => {
            this.setState({ service: res.data });
            this.setState({ description: res.data.description });
        })

        httpHelper.get('GetListServices').then((res) => {
            var data = res.data.filter(x => { return x.serviceId.toString() !== this.state.id });
            this.setState({ services: data });
        });
    }

    componentDidMount() {

    }

    OnRedirect = (currentId) => {        
        this.setState({ id: currentId });
        var searchpageurl = pageurl + '/integratedmobileapp/' + currentId;
        window.location.href = searchpageurl;       
    }
    render() {
        return (
            <>
                <Header transparent={false} />
                <PageTitle title={this.state.service.title} subtitle={this.state.description} bgImage={process.env.PUBLIC_URL + this.state.service.image} />

                <WhatWeDoContent>
                {ReactHtmlParser(this.state.service.content)}
                </WhatWeDoContent>

                <Service_WWD
                    service_id={this.state.id}
                />
                <Features_WWD
                    service_id={this.state.id}

                />
                <Benefits_WWD
                    service_id={this.state.id}
                />

                <section id="WhatWeDo_WWD" className="wbg">
                    <div className="container">
                        <div className="row  mb-5">
                            <div className="col-md-12">
                                <h2 className="heading">
                                    {this.state.service.heading}
                                </h2>
                            </div>
                        </div>
                        <div className="row">
                            {
                                this.state.services.map(
                                    ser =>
                                        <div className="col-md-6 mb-4">
                                            <div className="services_bg" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + ser.image})` }} >
                                                <div className="overlayServicesCard">
                                                </div>
                                                <div className="d-flex">

                                                    <div className="col-md-8 col-10 spacingData">
                                                        <h2 className="text-white content-desc 1">
                                                            {ser.title}
                                                        </h2>
                                                        <h3 class="text-white">{ser.description}</h3>
                                                    </div>

                                                    <div className="col-md-4 right_arrow">
                                                        <Link onClick={() => this.OnRedirect(ser.serviceId)}>
                                                            <span>
                                                                <MdOutlineKeyboardArrowRight />
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                )
                            }
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default IntegratedMobileApp
