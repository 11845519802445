import React, { memo } from "react";

const WhatWedoContent = (props) => {
    return (
        <>
            <section id="WhatWedoContent" className={`${props.className === 'white' ? 'bg-white' : ''}`} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>
                                {props.children}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(WhatWedoContent);