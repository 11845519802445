import React from "react";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import PageContent from "../components/PageContent";
import PageTitle from "../components/PageTitle";
import VisionMision from "../components/VisionMission";
import OurValues from "../components/OurValues";
import { Helmet } from 'react-helmet';

export default function AboutUs() {
    return (
        <>
            <Helmet>
                <title>Our Purpose,Vision and Mission</title>
                <meta name="description" content="With a clear purpose, vision, and mission, supported by deep-rooted values, and a well-deﬁned care model, TruDoc is recognized as a one-stop-care-destination focused on offering comprehensive end-to-end care for people who are healthy, acutely or chronically ill." />
                <meta name="theme-color" content="#0778b2" />
            </Helmet>
            <Header transparent={false} />
            <PageTitle title="Our Purpose,<br/>Vision and Mission" bgImage="assets/img/purpose-bg.jpg"/>
            <PageContent>
                With a clear purpose, vision, and mission, supported by deep-rooted values, and a well-deﬁned care model, TruDoc is recognized as a <b>one-stop-care-destination</b> focused on offering comprehensive <b>end-to-end care</b> for people who are healthy, acutely or chronically ill
            </PageContent>
            <VisionMision HeadingOne="Purpose"
                ParaOne="To re-design the healthcare delivery system in a way that impacts people’s lives positively"
               
                HeadingTwo="Vision"
                ParaTwo="Making health and wellness accessible and affordable to all, only one call away"
                
                HeadingThree="Mission"
                ParaThree="To implement ethical and unbiased care, aligning people and payers under one platform"
            />
            <OurValues heading="Values" />
            <Footer />
        </>
    );
}
