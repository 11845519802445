import React, { Component } from 'react'
import { disable } from 'workbox-navigation-preload';
import httpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from 'suneditor-react';


class ViewFAQComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            faqsContent: {},
            faqCategories: []
        }
    }

    componentDidMount() {
        httpHelper.get('GetListDropDownFaqCategory').then(response => {            
            this.setState({
                faqCategories: response.data
            });
        });
        httpHelper.getById('GetFaqsContentById', this.state.id).then(res => {
            /*console.log(res.data);*/
            this.setState({ faqsContent: res.data });
        })
    }

    cancel() {
        this.props.history.push('/faqsContent');
    }

    render() {
        return (

            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">FAQ</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Category: </label>
                                                            <select className="form-control" name="faqCategoryId" value={this.state.faqsContent.faqCategoryId}
                                                                onChange={this.changeFAQCategoryHandler} disabled="true" >
                                                                <option>Select Category</option>
                                                                {this.state.faqCategories.map((e, key) => {
                                                                    return <option key={key} value={e.dataValue}>{e.dataText}</option>;
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Question: </label>
                                                            <input placeholder="Question" name="question" className="form-control" readOnly={true}
                                                                value={this.state.faqsContent.questions} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Answer: </label>
                                                            {/*<input placeholder="Answer" name="answer" className="form-control" readOnly={true}*/}
                                                            {/*    value={this.state.faqsContent.answer} />*/}
                                                            <SunEditor name="answer" height="200" placeholder="Answer" setOptions={{
                                                               buttonList: [
                                                                /*['undo', 'redo', 'font', 'fontSize', 'formatBlock'],*/
                                                                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                                /*'/', //Line break*/
                                                                ['hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                                ['fontColor','fontSize', 'font'],
                                                                /*['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']*/
                                                            ],
                                                            }}
                                                                setContents={this.state.faqsContent.answers} defaultValue={this.state.answer} disable={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Back</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

export default ViewFAQComponent