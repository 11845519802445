import React from "react";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import PageTitle from "../components/PageTitle";
import WhatWeDoContent from "../components/WhatWeDoContent";
import Four_services from "../components/Four_services";
import Always from "../components/Always";
import { Helmet } from 'react-helmet';
import { ParallaxProvider } from 'react-scroll-parallax';


export default function WhyTruDoc() {
    return (
        <>
            <Helmet>
                <title>What We Do</title>
                <meta name="description" content="TruDoc offers a one-stop-care-destination for people who are healthy, acutely, or chronically ill through different entry points that are all connected to our 24×7 Doctor Call Center." />
                <meta name="theme-color" content="#0778b2" />
            </Helmet>
            <Header transparent={false} />
            <PageTitle title="What We Do" bgImage="assets/img/bg_whatwedo.png" />
            <WhatWeDoContent className="white">
                TruDoc offers a one-stop-care-destination for people who are healthy, acutely, or chronically ill through different entry points that are all connected to our 24×7 Doctor Call Center.
            </WhatWeDoContent>
            <ParallaxProvider>
                <Always className="white" />
            </ParallaxProvider>
            <Four_services
                Bordershadow={true}
            />

            <Footer />
        </>
    );
}
