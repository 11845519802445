import { React, memo } from "react";

const EntryPoints = (props) => {
    return (
        <>
            <section id="EntryPoints">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h1 className="text-ThemeTwo heading-Section text-center">{props.heading}</h1>
                        </div>
                        <div className="col-md-12 mt-Btn-Section">
                            <div className="row">
                                <div className="col-md-3 mb-5">
                                    <div className="imgEntryPoints mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/integration/1.png"} className="entrypointsImg" alt="10 years in operation" title="10 years in operation" />
                                    </div>
                                    <h6 className="subHeading">
                                        10 years in operation
                                    </h6>
                                </div>
                                <div className="col-md-3 mb-5">
                                    <div className="imgEntryPoints mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/integration/2.png"} className="entrypointsImg" alt="6700 corporate and government clients" title="6700 corporate and government clients" />
                                    </div>
                                    <h6 className="subHeading">
                                        6700 corporate and government clients
                                    </h6>
                                </div>
                                <div className="col-md-3 mb-5">
                                    <div className="imgEntryPoints mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/integration/3.png"} className="entrypointsImg" alt="24.2 million eligible members" title="24.2 million eligible members" />
                                    </div>
                                    <h6 className="subHeading">
                                        24.2 million eligible members
                                    </h6>
                                </div>
                                <div className="col-md-3 mb-5">
                                    <div className="imgEntryPoints mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/integration/4.png"} className="entrypointsImg" alt="0 malpractice claims" title="0 malpractice claims" />
                                    </div>
                                    <h6 className="subHeading">
                                        0 malpractice claims
                                    </h6>
                                </div>
                                <div className="col-md-3 mb-5">
                                    <div className="imgEntryPoints mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/integration/5.png"} className="entrypointsImg" alt="Multiple countries operations" title="Multiple countries operations" />
                                    </div>
                                    <h6 className="subHeading">
                                        Multiple countries operations
                                    </h6>
                                </div>
                                <div className="col-md-3 mb-5">
                                    <div className="imgEntryPoints mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/integration/6.png"} className="entrypointsImg" alt="Serving healthy, acute, and chronic cases" title="Serving healthy, acute, and chronic cases" />
                                    </div>
                                    <h6 className="subHeading">
                                        Serving healthy, acute, and chronic cases
                                    </h6>
                                </div>
                                <div className="col-md-3 mb-5">
                                    <div className="imgEntryPoints mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/integration/7.png"} className="entrypointsImg" alt="In-house mental health services" title="In-house mental health services" />
                                    </div>
                                    <h6 className="subHeading">
                                        In-house mental health services
                                    </h6>
                                </div>
                                <div className="col-md-3 mb-5">
                                    <div className="imgEntryPoints mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/integration/8.png"} className="entrypointsImg" alt="Inpatient and outpatient services" title="Inpatient and outpatient services" />
                                    </div>
                                    <h6 className="subHeading">
                                        Inpatient and outpatient services
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(EntryPoints);