import { React, memo } from "react";

const Timeline = (props) => {
    return (
        <>
            <section id="Milestone">
                <div className="container">
                    <div className="row align-center ">
                        <div className="col-md-12 mb-5">
                            <h1 className="heading-milestone mt-TitlePge text-center">Our Milestones</h1>
                        </div>
                        <div className="col-md-6 mob-display-none">
                            <p className="text-center mt-5" > <img src={process.env.PUBLIC_URL + "assets/img/timeline2.png"} alt="Our Milestone 2011" title="Our Milestone 2011" className="w-37" /></p>
                            <p className="text-center mt-5"> <img src={process.env.PUBLIC_URL + "assets/img/timeline3.png"} alt="Our Milestone 2016" title="Our Milestone 2016" className="w-37" /></p>
                            <p className="text-center mt-5"> <img src={process.env.PUBLIC_URL + "assets/img/timeline4.png"} alt="Our Milestone 2017" title="Our Milestone 2017" className="w-37" /></p>
                            <p className="text-center mt-5"> <img src={process.env.PUBLIC_URL + "assets/img/timeline5.png"} alt="Our Milestone 2018" title="Our Milestone 2018" className="w-37" /></p>
                            <p className="text-center mt-5"> <img src={process.env.PUBLIC_URL + "assets/img/timeline6.png"} alt="Our Milestone 2019" title="Our Milestone 2019" className="w-37"/></p>
                            <p className="text-center"> <img src={process.env.PUBLIC_URL + "assets/img/timeline1.png"} alt="Our Milestone 2020" title="Our Milestone 2020" className="w-37" /> </p>
                        </div>
                        <div class="timeline col-lg-6 col-sm-6">
                            <div class="containerr1 right">
                                <div class="content">
                                    <h6>2011 - 2015</h6>
                                    <ul className="text-dark mt-4 lh-2">
                                        <li>Established as the first telemedicine provider
                                            in the Middle East</li>
                                        <li>Signed contracts with insurance and telecom
                                            companies</li>
                                        <li>35,000 paid members</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="containerr1 right">
                                <div class="content">
                                    <h6>2016</h6>
                                    <ul className="text-dark mt-4 lh-2">
                                        <li>Launched an Integrated Mobile Application</li>
                                        <li>Introduced our On-site Company Doctor and
                                            Wellness service</li>
                                        <li>Established our Wellness Call Center</li>
                                        <li>Expanded operations and launched
                                            services in Egypt</li>
                                        <li>95,000+ paid members</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="containerr1 right">
                                <div class="content">
                                    <h6>2017</h6>
                                    <ul className="text-dark mt-4 lh-2">
                                        <li>Transformed from telemedicine to 24x7 Population
                                            Health Management</li>
                                        <li>Selected as the exclusive provider for the DHA
                                            Cancer and Hepatitis Patient Support Program</li>
                                        <li>Selected as the exclusive provider for the
                                            implementation of DHA Hospital at Home Program
                                            licensed by Johns Hopkins University</li>
                                        <li>Introduced 24x7 telemonitoring services</li>
                                        <li>Introduced Second Medical Opinion</li>
                                        <li>Signed strategic partnerships with DHA, Etisalat
                                            and Microsoft</li>
                                        <li>358+ multinational clients</li>
                                        <li>145,000+ paid members</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="containerr1 right">
                                <div class="content">
                                    <h6>2018</h6>
                                    <ul className="text-dark mt-4 lh-2">
                                        <li>Signed a strategic partnership with Roche Diabetes for
                                            our Diabetes Management Program</li>
                                        <li>Introduced Chronic Disease Management Program</li>
                                        <li>Introduced 24x7 Health and Wellness Virtual Clinic</li>
                                        <li>Expanded operations and launched services in Nigeria</li>
                                        <li>1000+ multinational clients</li>
                                        <li>300,000+ paid members</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="containerr1 right">
                                <div class="content">
                                    <h6>2019</h6>
                                    <ul className="text-dark mt-4 lh-2">
                                        <li>Operated the DHA Cancer and HCV PSP Program</li>
                                        <li>Consulted & Operated the DHA Doctor
                                            for Every Citizen telemedicine service</li>
                                        <li>Introduced our Hospitalist Program</li>
                                        <li>3.4 million paid members</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="containerr1 right">
                                <div class="content">
                                    <h6>2020</h6>
                                    <ul className="text-dark mt-4 lh-2">
                                        <li>Celebrated 10 years in the industry</li>
                                        <li>Introduced Mental Health Services</li>
                                        <li>Established Eko Telemedicine for COVID-19
                                            in the Lagos State, Nigeria</li>
                                        <li>Established the COVID-19 Home-Based Care for
                                            the residents of Lagos State, Nigeria</li>
                                        <li>4.4 million paid members</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="containerr1 right">
                                <div class="content">
                                    <h6>2021</h6>
                                    <ul className="text-dark mt-4 lh-2">
                                        <li>Launched the Post-Arrival Covid Care Project
                                            for the Lagos State, Nigeria</li>
                                        <li>Started operations in Kenya in partnership
                                            with HealthX</li>
                                        <li>Identified and signed agreements with local
                                            partners in 10 African countries</li>
                                        <li>23 million eligible members under our
                                            COVID-program with the Lagos State Health
                                            Management Agency (LASHMA), Nigeria</li>
                                        <li>Signed contracts with major insurance
                                            companies in Abu Dhabi</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="containerr1 right">
                                <div class="content">
                                    <h6>2022</h6>
                                    <ul className="text-dark mt-4 lh-2">
                                        <li>Started operations in South Africa</li>
                                        {/*<li>Soon to start operations in Jordan, Zimbabwe, Mozambique, Uganda and pakistan?</li>*/}
                                    </ul>
                                </div>
                            </div>
                            <div class="containerr1 right">
                                <div class="content v-hidden">
                                    <h6></h6>
                                   
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(Timeline);