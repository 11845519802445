import React, { Component } from 'react'
import { disable } from 'workbox-navigation-preload';
import httpHelper from '../../../httpHelper';
import DashboardHeader from '../../Dashboard/DashboardHeader';
import Sidebar from '../../Dashboard/Sidebar';
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from 'suneditor-react';

class ViewSubServiceComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            services: [],
            subServices: {}
        }
    }

    componentDidMount() {
        httpHelper.get('GetListDropDownServices').then(response => {
            console.log(response.data);
            this.setState({
                services: response.data
            });
        });

        httpHelper.getById('GetSubServicesById', this.state.id).then(res => {
            this.setState({ subServices: res.data });
        })
    }

    cancel() {
        this.props.history.push('/subService');
    }

    render() {
        return (

            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Sub Service</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Service: </label>
                                                            <select className="form-control" name="serviceId" value={this.state.subServices.serviceId} disabled="true" >
                                                                <option>Select Service</option>
                                                                {this.state.services.map((e, key) => {
                                                                    return <option key={key} value={e.dataValue}>{e.dataText}</option>;
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control" readOnly={true}
                                                                value={this.state.subServices.title} />
                                                        </div>
                                                        <div className="form-check form-check-flat form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" name="isActive" className="form-check-input"
                                                                    checked={this.state.subServices.isVideo} disabled={true} />
                                                                Active
                                                                <i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-group">
                                                            <img width='500' height='200' src={this.state.subServices.image} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Content: </label>
                                                            {/*<input placeholder="Content" name="content" className="form-control" readOnly={true}*/}
                                                            {/*    value={this.state.subServices.content} />*/}
                                                            <SunEditor name="content" height="400" placeholder="Content" setOptions={{
                                                               buttonList: [
                                                                /*['undo', 'redo', 'font', 'fontSize', 'formatBlock'],*/
                                                                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                                /*'/', //Line break*/
                                                                ['hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                                ['fontColor','fontSize', 'font'],
                                                                /*['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']*/
                                                            ],
                                                            }}
                                                                setContents={this.state.subServices.content} defaultValue={this.state.subServices.content} disable={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Back</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

export default ViewSubServiceComponent