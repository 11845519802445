import React, { Component } from 'react'
import HttpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ListHealthCategoryComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            homes: []
        }
        this.addHealthCategory = this.addHealthCategory.bind(this);
        this.editHealthCategory = this.editHealthCategory.bind(this);
        this.deleteHealthCategory = this.deleteHealthCategory.bind(this);
    }

    deleteHealthCategory(id) {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => HttpHelper.delete('DeleteHealthLibraryContents', id).then(res => {
                        this.setState({ homes: this.state.homes.filter(home => home.id !== id) });
                        toast("Record delete successfully!");
                        this.props.history.push('/health');
                        /*setInterval(() => { this.props.history.go('/health'); }, 1000);*/
                    })
                },
                {
                    label: 'No',
                    onClick: () => { this.props.history.push('/health'); }
                }
            ]
        });
    }
    viewHealthCategory(id) {
        this.props.history.push(`/view-healthlib/${id}`);
    }
    editHealthCategory(id) {
        this.props.history.push(`/add-health/${id}`);
    }

    componentDidMount() {
        HttpHelper.get('GetListHealthLibraryContents').then((res) => {
            this.setState({ homes: res.data });
        });
    }

    addHealthCategory() {
        this.props.history.push('/add-health/_add');
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Health Library</h4>
                                            <div className="row">
                                                <div className="col-md-10">
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-primary btn-sm" style={{ float: "right" }} onClick={this.addHealthCategory}> Add</button>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="row">
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th> Category</th>
                                                                <th> Title</th>
                                                                <th> Description</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.homes.map(
                                                                    home =>
                                                                        <tr key={home.id}>
                                                                            <td> {home.healthCategory} </td>
                                                                            <td> {home.title} </td>
                                                                            <td> {home.description} </td>
                                                                            {/* <td>{home.bannerImage}</td> */}
                                                                            <td style={{ textAlign: "right" }}>
                                                                                <button onClick={() => this.editHealthCategory(home.id)} className="btn btn-primary btn-sm">Update </button>
                                                                                <button style={{ marginLeft: "10px" }} onClick={() => this.deleteHealthCategory(home.id)} className="btn btn-danger btn-sm">Delete </button>
                                                                                <button style={{ marginLeft: "10px" }} onClick={() => this.viewHealthCategory(home.id)} className="btn btn-primary btn-sm">View </button>
                                                                            </td>
                                                                        </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default ListHealthCategoryComponent