import React, { memo } from "react";

const BoardMembers = () => {
  return (
    <>
          <section id="BoardMembers">
              <div className="container">
                  <div className="row">
                      <div className="col-md-4">
                          <div className="BoardBox">
                              <div className="BoardBoxImg">
                                  <img
                                      src={process.env.PUBLIC_URL + "/assets/img/board-vish.png"}
                                      className="img-fluid"
                                      alt="Vish Narain"
                                      title="Founder and Group CEO TruDoc"
                                  />
                              </div>
                              <div className="BoardBoxName">
                                  <h3>Vish Narain</h3>
                              </div>
                              <div className="BoardBoxDesignation">
                                  <p>Chairman​</p>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-4">
                          <div className="BoardBox">
                              <div className="BoardBoxImg">
                                  <img
                                      src={process.env.PUBLIC_URL + "/assets/img/board-asad.png"}
                                      className="img-fluid"
                                      alt="Asad Khan"
                                      title="Asad Khan CEO TruDoc Middle East - KSA"
                                  />
                              </div>
                              <div className="BoardBoxName">
                                  <h3>Asad Khan</h3>
                              </div>
                              <div className="BoardBoxDesignation">
                                  <p>CEO KSA</p>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-4">
                          <div className="BoardBox">
                              <div className="BoardBoxImg">
                                  <img
                                      src={
                                          process.env.PUBLIC_URL + "/assets/img/board-abhishek.png"
                                      }
                                      className="img-fluid"
                                      alt="Abhishek Shah"
                                      title="Abhishek Shah President TruDoc Middle East"
                                  />
                              </div>
                              <div className="BoardBoxName">
                                  <h3>Abhishek Shah</h3>
                              </div>
                              <div className="BoardBoxDesignation">
                                  <p>President</p>
                              </div>
                          </div>
                      </div>

                  </div>
                  <div className="row">

                      <div className="col-md-4">
                          <div className="BoardBox">
                              <div className="BoardBoxImg">
                                  <img
                                      src={process.env.PUBLIC_URL + "/assets/img/board-mahdi.png"}
                                      className="img-fluid"
                                      alt="Mahdi Attya"
                                      title="Mahdi Attya CCO TruDoc Middle East"
                                  />
                              </div>
                              <div className="BoardBoxName">
                                  <h3>Mahdi Attya</h3>
                              </div>
                              <div className="BoardBoxDesignation">
                                  <p>CCO</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-4">
                          <div className="BoardBox">
                              <div className="BoardBoxImg">
                                  <img
                                      src={process.env.PUBLIC_URL + "/assets/img/board-moin.png"}
                                      className="img-fluid"
                                      alt="Moin Kazi"
                                      title="Moin Kazi COO TruDoc Healthcare"
                                  />
                              </div>
                              <div className="BoardBoxName">
                                  <h3>Moin Kazi</h3>
                              </div>
                              <div className="BoardBoxDesignation">
                                  <p>COO</p>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-4">
                          <div className="BoardBox">
                              <div className="BoardBoxImg">
                                  <img
                                      src={process.env.PUBLIC_URL + "/assets/img/board-amit.png"}
                                      className="img-fluid"
                                      alt="Amit Chhajer"
                                      title="Amit Chhajer CTO TruDoc Healthcare"
                                  />
                              </div>
                              <div className="BoardBoxName">
                                  <h3>Amit Chhajer</h3>
                              </div>
                              <div className="BoardBoxDesignation">
                                  <p>CTO</p>
                              </div>
                          </div>
                      </div>


                  </div>
                  <div className="row justify-content-center">

                   


                      <div className="col-md-4">
                          <div className="BoardBox">
                              <div className="BoardBoxImg">
                                  <img
                                      src={process.env.PUBLIC_URL + "/assets/img/board-utsav.jpg"}
                                      className="img-fluid"
                                      alt="Utsaav"
                                      title="Utsaav"
                                  />
                              </div>
                              <div className="BoardBoxName">
                                  <h3>Utsaav Pathak</h3>
                              </div>
                              <div className="BoardBoxDesignation">
                                  <p>Director Finance</p>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-4">
                          <div className="BoardBox">
                              <div className="BoardBoxImg">
                                  <img
                                      src={
                                          process.env.PUBLIC_URL + "/assets/img/board-dr-tarek.png"
                                      }
                                      className="img-fluid"
                                      alt="Dr Tarek Turk"
                                      title="Dr Tarek Turk CMO TruDoc Healthcare"
                                  />
                              </div>
                              <div className="BoardBoxName">
                                  <h3>Dr Tarek Turk</h3>
                              </div>
                              <div className="BoardBoxDesignation">
                                  <p>CMO</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
    </>
  );
};

export default memo(BoardMembers);
