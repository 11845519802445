import React, { Component } from 'react'
import { disable } from 'workbox-navigation-preload';
import HttpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';

class ViewSettingComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            setting: {}
        }
    }

    componentDidMount() {
        HttpHelper.getById('GetSettingById', this.state.id).then(res => {
            this.setState({ setting: res.data });
        })
    }

    cancel() {
        this.props.history.push('/setting');
    }

    render() {
        return (

            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Setting</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Name: </label>
                                                            <input placeholder="Name" name="name" className="form-control" readOnly={true}
                                                                value={this.state.setting.name} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Value: </label>
                                                            <input placeholder="Value" name="value" className="form-control"
                                                                value={this.state.setting.value} readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Description: </label>
                                                            <input placeholder="Description" name="dDescription" className="form-control" readOnly={true}
                                                                value={this.state.setting.description} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Back</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

export default ViewSettingComponent