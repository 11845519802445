import React from 'react';
import Sidebar from '../Dashboard/Sidebar';
import TextWithOutGradientImageFullWidth from '../TextWithOutGradientImageFullWidth';


export default function ContentDashboard() {
    const bgBrush = process.env.PUBLIC_URL + 'assets/img/brush-effect.png';
    return (
        <>
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <TextWithOutGradientImageFullWidth gradient={true} bgImage="assets/img/bg-3.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
