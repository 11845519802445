import React, { memo } from "react";


const SubServiceContent = (props) => {
    return(
    <>
            <section id="SubServiceContent">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-12">
                            <p className="text-thm-blue">
                                <b>{props.main_heading}</b>
                            </p>
                        </div>
                    </div>
                    <div className="row align-center">
                        <div className="col-md-6">
                            <img src={process.env.PUBLIC_URL + props.image1} alt={props.heading1} title={props.heading1} className="w-100 img-responsive"  />
                        </div>
                        <div className="col-md-6 popRes">
                            <h2 className="text-thm-green">{props.heading1}</h2>
                            <p className="">
                                {props.para1} </p>
                        </div>
                    </div>
                    <hr className="mt-5 mb-5 height05"/>
                    <div className="row align-center resFlexSwap">
                        <div className="col-md-6 popRes">
                            <h2 className="text-thm-green">{props.heading2}</h2>
                            <p className="">
                                {props.para2}
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img src={process.env.PUBLIC_URL + props.image2} alt={props.heading2} title={props.heading2} className="w-100 img-responsive" />
                        </div>
                    </div>
                    <hr className="mt-5 mb-5 height05" />
                    <div className="row align-center">
                        <div className="col-md-6">
                            <img src={process.env.PUBLIC_URL + props.image3} alt={props.heading3} title={props.heading3} className="w-100 img-responsive" />
                        </div>
                        <div className="col-md-6 popRes">
                            <h2 className="text-thm-green">{props.heading3}</h2>
                            <p className="">
                                {props.para3}  </p>
                        </div>
                    </div>
                </div>
            </section>
    </>
);
}

export default memo(SubServiceContent);