import React, { Component, memo } from "react";
import httpHelper from '../httpHelper';

export class HomeSlider extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Data: [],
            sliderLoading: true,
        }
    }

    componentDidMount() {
        httpHelper.get('GetListHome').then(response => {
            console.log(response.data);
            this.setState({
                Data: response.data,
                sliderLoading: false,
            });
        });
    }

    render() {
        return (
            this.state.Data.length > 0 ?
                <section id='homeSlider' className='bgGradientLight'>
                    <div className='container'>
                        <div className='row'>
                            <div className={this.state.sliderLoading == true ? 'col-md-12 text-center' : 'col-md-12' }>
                                {
                                    this.state.sliderLoading == false ?
                                        <div className="homepageSlideImages">
                                            <div className="slideOne">
                                                <img src={this.state.Data[0].bannerImage} alt={this.state.Data[0].title} title={this.state.Data[0].title} />
                                                <h4 className="sliderHeading">{this.state.Data[0].title}</h4>
                                            </div>
                                            <div className="slideTwo">
                                                <h4 className="sliderHeading">{this.state.Data[1].title}</h4>
                                                <img src={this.state.Data[1].bannerImage} alt={this.state.Data[1].title} title={this.state.Data[1].title} />
                                            </div>
                                            <div className="slideThree">
                                                <img src={this.state.Data[2].bannerImage} alt={this.state.Data[2].title} title={this.state.Data[2].title} />
                                                <h4 className="sliderHeading">{this.state.Data[2].title}</h4>
                                            </div>
                                            <div className="slideFour">
                                                <img src={this.state.Data[3].bannerImage} alt={this.state.Data[3].title} title={this.state.Data[3].title} />
                                                <h4 className="sliderHeading">{this.state.Data[3].title}</h4>
                                            </div>
                                        </div>
                                        : <div style={{ "color": "#097fb2", "textAlign":"center" }} class="spinner-border text-center" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>  
                                }
                                </div>
                            </div>
                           
                    </div>
                    <div className="continer mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="SliderPageHeading">
                                    <img src={process.env.PUBLIC_URL + "assets/img/always-slide.svg"} className="img-fluid p-2 mt-2 always-img-slide" alt="Always With You" title="Always With You" />
                                </h2>
                            </div>
                        </div>
                    </div>
                </section>
                : <section id='homeSlider' className='bgGradientLight'></section>
        )
    }
}
export default memo(HomeSlider);