import React, { memo } from "react";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import PageContent from "../components/PageContent";
import PageTitle from "../components/PageTitle";
import WhoWeServeTwoCol from "../components/WhoWeServeTwoCol";
import CallButton from "../components/CallButton";
import { Helmet } from 'react-helmet';

const Corporates = () => {
    return (
        <>
            <Helmet>
                <title>Who We Serve | For Corporates</title>
                <meta name="description" content="With TruDoc, corporates can control annual increases in insurance premiums For Governments:" />
                <meta name="theme-color" content="#0778b2" />
            </Helmet>
            <Header transparent={false} />
            <PageTitle title="Corporates" bgImage="assets/img/Corporate-page.png" />
            <PageContent class="bg-white" >
                Employees are a company’s greatest asset and an integral part of a company’s success. Therefore, it is essential to keep them happy, healthy, and motivated.
                <br />
                <br />
                Providing a good healthcare plan is a sought-after perk that candidates look forward to when considering a job opportunity, and according to research, it also plays a vital role in employee retention.
                <br />
                <br />
                As the cost of health insurance continues to rise, corporates have no choice but to change their plans or reduce the health benefits of their employees.

            </ PageContent>
            <PageContent class="" >

                <h2 class="headingLgPrimary">
                    With TruDoc, corporates can:
                </h2>

                <div class="row">
                    <div className="col-md-6">
                        <ul class="truoc_ul_center">
                            <li class="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" class="li_tick_center" />Control annual increases in insurance premiums</li>
                            <li class="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" class="li_tick_center" />Control the use of healthcare services and frequency of outpatient visits</li>
                            <li class="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" class="li_tick_center" />Reduce sick leaves and absenteeism</li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul class="truoc_ul_center">

                            <li class="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" class="li_tick_center" />Optimize medical loss ratio</li>
                            <li class="fs-20"><img src="assets/img/tick.png" alt="Tick" title="tick" class="li_tick_center" />Control inpatient cost by optimizing hospital stay and extending care at home</li>
                        </ul>
                    </div>
                </div>

            </ PageContent>
            
            <section id="SubscriptionPlans">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="headingLgPrimary">Subscription Plans</h2>
                        </div>
                        <div className="col-md-12">
                            <div className="boxContactSubscription">
                                <div className="boxContactSubscriptionDetails">
                                    Customized offering based <br /> on your needs
                                </div>
                                <CallButton icon="" content="Contact Us" url="/contactus" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <WhoWeServeTwoCol heading="Solutions"
                imageOne="assets/img/corporates-gov.png"
                HeadingOne="For Individuals"
                urlOne="/individuals"
                imageTwo="assets/img/corporates-Individuals.png"
                HeadingTwo="For Governments"
                urlTwo="/governments"
            />
            <Footer />
        </>
    )
}

export default memo(Corporates);