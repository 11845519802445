import React, { memo } from "react";


const SubServiceOffered = (props) => {
    return (
        <>
            <section id="SubServiceOffered2">
                <div className="container">
                    <div className="row mb-5 mt-5">
                        <div className="col-md-12">

                            <p>
                                Based on the concept that the more educated an individual is about managing and controlling their condition the better the health outcome, TruDoc’s Patient Management Program Program is designed to improve the health of people with chronic conditions and reduce associated costs through
                                coordinated early intervention.
                            </p>
                        </div>
                    </div>
                    <div className="row mb-5 mt-5">
                        <div className="col-md-12 mb-5">
                            <h2 className="text-thm-blue">We help manage a range of chronic conditions including:</h2>
                        </div>
                        <div className="col-md-4">
                            <ul className="truoc_ul ">
                                <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Asthma</li>
                                <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Depression</li>
                                
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <ul className="truoc_ul ">
                                <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Arthritis</li>
                                <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Hypertension</li>
                               
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <ul className="truoc_ul ">
                                <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Diabetes</li>
                                <li className=""><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Multiple conditions</li>
                               
                            </ul>
                        </div>

                    </div>
                </div>
            </section>
            
            
            <section id="Benefits_WWD">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <h2 className="heading">
                                Features and Benefits
                            </h2>
                        </div>
                        <div className="col-md-6" >
                            <ul className="truoc_ul" >
                                <li className="text-grey mb-28"><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />24x7 access to doctor consultations through
                                    voice and video call</li>
                                <li className="text-grey mb-28"><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Ongoing counseling for condition management
                                    and medication adherence</li>
                                <li className="text-grey mb-28"><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Personalized nutrition plan</li>
                                <li className="text-grey mb-28"><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Appointment tracker and medication reminders</li>
                                <li className="text-grey mb-28"><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Medication prescriptions and refills</li>
                                <li className="text-grey mb-28"><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />At-home lab tests and medication delivery,
                                    where available</li>
                                <li className="text-grey mb-28"><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Referrals and appointment booking,
                                    when necessary</li>

                             </ul>
                         </div>
                         <div className="col-md-6" >
                            <ul className="truoc_ul" >
                                <li className="text-grey mb-28"><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Improved quality of life
                                    </li>
                                <li className="text-grey mb-28"><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Reduced healthcare cost
                                   </li>
                                <li className="text-grey mb-28"><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Reduced hospital visits</li>
                                <li className="text-grey mb-28"><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Unbiased doctor's advice</li>
                                <li className="text-grey mb-28"><img src={process.env.PUBLIC_URL + "assets/img/tick-theme-two.png"} alt="Tick" title="Tick" className="li_tick" />Convenient and accessible care</li>
                                 
                             </ul>
                         </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default memo(SubServiceOffered);