import React, { memo } from "react";
import EntryPoints from "../components/EntryPoints";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import HomeSlider from "../components/HomeSlider";
import TextWithImageFullWidth from "../components/TextWithImageFullWidth";
import TextWithImageFullWidthRight from "../components/TextWithImageFullWidthRight";
import TextWithOutGradientImageFullWidth from "../components/TextWithOutGradientImageFullWidth";
import WhoWeServe from "../components/WhoWeServe";
import WhatWeDo from "../components/WhatWeDo";
import OurClients from "../components/OurClients";
import OurPartners from "../components/OurPartners";
import MobileApp from "../components/MobileApp";
import Testimonial from "../components/Testimonial";
import SocialFeed from "../components/SocialFeed";
import ReviewUser from "../components/ReviewUser";
import WhyTruDoc from "../components/WhyTruDoc";
import CallButton from "../components/CallButton";
const HomePage = () => {   

    const bgBrush = process.env.PUBLIC_URL + 'assets/img/brush-effect.png';
    return (
        <>

            <Header transparent={true} />
            <HomeSlider />
            <TextWithImageFullWidth bgImage="assets/img/who-we-are.png">
                <div className="col-md-5">
                    <h2>
                        We are <br/> re-designing the healthcare delivery system in a way <br/> that impacts people's lives positively
                    </h2>
                    <CallButton content="Learn More" url="/purpose" icon="" class="mt-Btn-Section padding-button" />
                </div>
            </TextWithImageFullWidth>
            <EntryPoints heading="Integrating and managing all healthcare entry points" />
            <ReviewUser heading="Current  Healthcare Reality" imgReview="assets/img/einstien.png" />
            <WhyTruDoc heading="Why TruDoc?" bgImage="assets/img/why-tru-doc.png" />
            <WhatWeDo heading="What We Do" />
            <WhoWeServe heading="Who We Serve"
                imageOne="assets/img/who-we-serve1.png"
                HeadingOne="Corporates"
                imageTwo="assets/img/who-we-serve2.png"
                HeadingTwo="Governments "
                imageThree="assets/img/who-we-serve.png"
                HeadingThree="Individuals"
            />

            <TextWithOutGradientImageFullWidth gradient={true} class="TextDecorate" button={false} heading="Health Library" bgImage="assets/img/bg-3.png">
                <h2> TruDoc services <br/> are available for <br/>  <span className='TextDecorate' style={{ backgroundImage: `url(${bgBrush})` }} >24,200,000 eligible members</span> through <span className='TextDecorate' style={{ backgroundImage: `url(${bgBrush})` }} >6,700 corporates clients,</span> insurance carriers, brokage <br/> firms, and <span className='TextDecorate' style={{ backgroundImage: `url(${bgBrush})` }} > governments </span>in <br/> multiple countries</h2>
            </TextWithOutGradientImageFullWidth>

            {/*<OurClients heading="Our Clients" />*/}
            <OurPartners heading="Our Partners" />
            <TextWithImageFullWidthRight heading="Health Library" bgImage="assets/img/health-liberary.png" />
            <TextWithOutGradientImageFullWidth class="TextDecorate" button={false} heading="Health Library" bgImage="assets/img/we-provide.png">
                <h2>We provide <span className='TextDecorate' style={{ backgroundImage: `url(${bgBrush})` }} >convenient,</span> <br /> <span className='TextDecorate' style={{ backgroundImage: `url(${bgBrush})` }} >accessible</span> and <span className='TextDecorate' style={{ backgroundImage: `url(${bgBrush})` }} >quality</span> <br /> healthcare to people <br /> anywhere, anytime       </h2>     </TextWithOutGradientImageFullWidth>
            <MobileApp />
            <Testimonial heading="User Testimonial" />
            <SocialFeed heading="Follow us on social media" urlOne="https://www.instagram.com/trudocgroup/" urlTwo="https://www.facebook.com/trudoc" urlThree="https://www.linkedin.com/company/trudoc24x7" />
            <Footer />
        </>
    );


}

export default memo(HomePage);