import React, { memo, useEffect } from "react";
import BoardMembers from "../components/BoardMembers";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import PageContent from "../components/PageContent";
import PageTitle from "../components/PageTitle";
import { Helmet } from 'react-helmet';

const PrivacyAndPolicy = () => {

    //const titleWebsite = "Database | Xetech";
    //const descriptionWebsite = "Being a specialised software development company, we help our clients with database solutions and extract valuable business insights from their existing database and data to understand their needs better using analysis tools and artificial intelligence.";
    //useEffect(() => {
    //    document.title = titleWebsite;
    //    document.getElementsByTagName("meta")[2].content = descriptionWebsite;
    //    document.description = descriptionWebsite;
    //    window.scrollTo(0, 0);
    //}, [titleWebsite, descriptionWebsite]);


    return (
        <>
            <Helmet>
                <title>Privacy And Policy</title>
                <meta name="description" content="With TruDoc, individuals can get instant access to 24x7 reliable, convenient, and affordable health care. " />
                <meta name="theme-color" content="#0778b2" />
            </Helmet>
            <Header transparent={false} />
            <PageTitle title="Privacy Policy" bgImage="assets/img/privacy-bg.png" />
            <PageContent class="privacy-h-b WhiteHeader-policy" >
                TruDoc Healthcare LLC (“TruDoc” “we” or “us”) is committed to ensuring that your privacy is protected, and it is the policy of TruDoc to comply with all local and international Data Protection and Privacy laws and regulations. TruDoc understands the importance of safeguarding the security and integrity of all personal information submitted to it via this website.
                <br /><br />
                TruDoc offers 24x7 health population management and Services (the “Services”). This Privacy Policy applies to our website https://trudoc24x7.com(the “Site”), as well as the services on TruDoc Mobile Application, which are accessible through the site. In this Privacy Policy (“Policy”), we describe how we collect, use, and disclose information that we obtain about visitors to our Site and users of Our services.
                <br /><br />
                By visiting the site or using Our services, you agree that your personal information will be handled as
                described in this Policy. Your use of our Site and Services, and any dispute over privacy is subject to this Policy and our Terms, including its applicable limitations on damages and the resolution of disputes. The TruDoc Terms of Service are incorporated by reference into this Policy.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-green">What information do we collect about you and why?</p>
                We may collect information about you directly from you and from third parties, as well as automatically through your use of our Site or Services.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">Information We Collect Directly from You.</p>

                Users may browse the informational areas of the site without registering with. However, registration is required to use the services on our mobile application. Once a patient has registered with us and
                subscribed to Our services, the Healthcare Provider can then invite patients to use the services. After receiving an invite, the Patient would then need to register for a TruDoc account to use the services.
                <br /><br />
                Patient Registration.  Patients must provide certain information, including: name, date of birth, gender, national ID, email and mobile phone, as well as a password.  We may also collect certain optional
                information, including: country, city, picture and communications preferences.  In addition, the Patient can communicate other health-related information to the Healthcare Provider during a video
                consultation via the TruDoc app.
                <br /><br />
                Other.  We also collect other information that you submit on our Site, such as comments and blog postings (see “User Generated Content” section below).  You must submit your name to post a
                comment to our blog and you may submit other optional information as well.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">Information We Collect from Healthcare Providers. </p>
                Certain features and Services are available to patients because their Healthcare Providers use Our services. Healthcare Providers may provide patient names and e-mail addresses to us so we may communicate with patients on their behalf about the availability of certain Services.
                <br/><br/>
                Information We Collect Automatically. We may automatically collect the following information about your use of our Site or Services through cookies, web beacons, and other technologies: your domain name; your browser type and operating system; web pages you view; links you click; your IP address; a time and date stamp and the length of time you visit our Site and or use Our services; the referring URL, or the webpage that led you to our Site; and your browser type. We may combine this information with other information that we have collected about you, including, where applicable, your user name, name, and other personal information. Please see the section “Our Use of Cookies and Other Tracking Mechanisms” below for more information about our use of cookies and other tracking mechanisms.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">How we use your information</p>
                All information shared with our authorized personnel/staff/representatives is strictly confidential. We shall not disclose any information without your consent unless so required under law. However, in the event of any requirement pertaining to your care or additional services thereto, we may need to
                discuss your case with another healthcare provider or service provider, subject to your consent.
                <br /><br />
                We use the information that we gather about you for the following purposes:
                <br /><br />
                To provide Our services.
                <br /><br />
                To communicate with you about your use of Our services, to respond to your inquiries, to provide technical support and assistance and for other customer service purposes.
                <br /><br />
                To communicate with you regarding the availability of Services on behalf of your Healthcare Provider, otherwise, we do not market to patients.
                <br /><br />
                To send you reminders and other information related to your care on behalf of your Healthcare Provider.
                <br /><br />
                To tailor the content and information that we may send or display, to offer location customization, and personalized help and instructions, and to otherwise personalize your experiences while using the site or Our services.
                <br /><br />
                To send offers and other promotional communications to Healthcare Providers;
                <br /><br />
                To display advertising on our Site.
                <br /><br />
                To better understand how users access and use Our services, both on an aggregated and individualized basis, in order to improve our Site and Services and respond to user desires and preferences, and for other research and analytical purposes.


                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">How we share your information</p>
                We may share the information that we collect about you, including personally identifiable information, as follows:
                <br /><br />
                Healthcare Providers. As a Patient, your information will be shared with Healthcare Providers as
                directed and consented to by you. Our services make your Assessments and related information available to your Healthcare Provider.   We will not make information available to Healthcare Providers other than those with whom you have requested that we share your Assessments.  This Policy does not address how Healthcare Providers will use and disclose information obtained using TruDoc.  If you would like this information, you should ask your Healthcare Provider directly for a copy of his/her Notice of Privacy Practices.
                <br /><br />
                Service Providers. We may disclose the information we collect from you to third party vendors, service providers, contractors or agents who perform functions on our behalf.
                <br /><br />
                Business Transfers. If we are acquired by or merged with another company, if substantially all of our assets are transferred to another company, or if we are a part of a bankruptcy proceeding, we may transfer the information we have collected from you to the other Company.
                <br /><br />
                In Response to Legal Process. We also may disclose the information we collect from you in order to comply with the law, a judicial proceeding, court order, or other legal process, such as in response to a subpoena in compliance with applicable privacy laws.
                <br /><br />
                To Protect Us and Others. We also may disclose the information we collect from you, to the extent
                permitted by law, where we believe it is necessary to investigate, prevent, or take action regarding
                illegal activities, suspected fraud, situations involving potential threats to the safety of any person,
                violations of our Terms of Use or this Policy, or as evidence in litigation in which TruDoc is involved.
                <br /><br />
                Aggregate and De-Identified Information. We may share aggregate or de-identified information about users with third parties for marketing, advertising, research or similar purposes.



                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">Our use of cookies and other tracking mechanisms</p>

                We use cookies and other tracking mechanisms to track information about your use of our Site or
                Services. We may combine this information with other information we collect from you.
                <br /><br />
                <strong className="text-thm-blue">Cookies.</strong>  Cookies are alphanumeric identifiers that we transfer to your computer’s hard drive through your web browser for record-keeping purposes. Some cookies allow us to make it easier for you to navigate our Site and Services, while others are used to enable a faster log-in process or to allow us to track your activities at our Site and Service.  Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block them in the future. The Help portion of the
                toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. Visitors to our Site who disable cookies will be able to browse public areas of the site, but the services will not function.
                <br /><br />
                <strong className="text-thm-blue">Third Party Analytics.</strong>We use automated devices and applications to evaluate usage of our Site and Services and to help us personalize content on out Site and Services. We also may use other analytic means to evaluate Our services. We use these tools to help us improve Our services, performance and user experiences. These entities may use cookies and other tracking technologies to perform their
                services. We do not share your personal information with these third parties.
                <br /><br />
                <strong className="text-thm-blue">Advertising.</strong>. We may use network advertisers to advertise our Site and Services on other websites,
                and we may do so based on your visits to our Site and to other third-party Sites. These entities may use cookies and other tracking technologies to perform their services. We do not share your personal
                information with these third parties.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">Third-party links</p>
                Our Site and Services may contain links to third-party websites. Any access to and use of such linked websites is not governed by this Policy, but instead is governed by the privacy policies of those third-party websites. We are not responsible for the information practices of such third-party websites.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">Patient health information</p>
                The privacy and security of Patients’ individually identifiable health information provided to TruDoc in connection with Services may be protected by UAE federal law and emirate specific law, including but not limited to UAE Federal Law No. 2 of 2019 concerning the use of information and communication technology in the area of law, Federal Law No. 7 of 1975 Concerning the Practice of Human Medicine Profession, The Ministry of Health Code of Conduct 1988, Dubai Healthcare City Governing Regulation No. 7 of 2008, The Health Authority Abu Dhabi’s Data Standards, and their regulations and codes,
                because TruDoc provides Services to health care providers.  This health information is “protected health information” (“PHI”).  PHI may be used and disclosed by TruDoc as necessary to provide
                Services, for our own management and operations, to meet our legal obligations, and for any other purpose for which patients have given consent.  We may share PHI with third parties for these
                purposes in compliance with applicable law. We may de-identify PHI and aggregate it for purposes of monitoring and improving our products and services, for benchmarking purposes, and to provide
                customized services or technologies our customers.

                 <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">Security of your personal information</p>
                We have implemented reasonable technical, physical, administrative, and organizational safeguards to protect the information we collect from loss, misuse, and unauthorized access, disclosure, alteration, and destruction.  Please be aware that despite our efforts, no data security measures can guarantee 100% security.  You should take steps to protect against unauthorized access to your password, phone, and computer by, among other things, signing off after using a shared computer, choosing a robust password that nobody else knows or can easily guess, and keeping your log-in and password private. We are not responsible for any lost, stolen, or compromised passwords or for any activity on your
                account via unauthorized password activity.
                <br /><br />
                All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties. We will not share any debit/credit card details to third parties.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">Access to Your Personal Information</p>
                You may modify registration information that you have submitted by logging into your account and
                updating your profile information. Please note that copies of information that you have updated,
                modified or deleted may remain viewable in cached and archived pages of the site for a period of time.
                <br /><br />
                Our contact center will provide a PHYSICIAN ASSESSMENT of your condition. This assessment is not
                designed to replace the EMERGENCY NUMBER or an EMERGENCY ROOM for a TRUE EMERGENCY.

                <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">Marketing emails</p>
                We may send periodic promotional or informational emails to Healthcare Providers. Healthcare
                Providers may opt-out of such communications by following the opt-out instructions contained in the e-mail. Please note that it may take up to 10 business days for us to process opt-out requests. If you opt-out of receiving emails about recommendations or other information we think may interest you, we may still send you e-mails about your account or any Services you have requested or received from us.

                 <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">Contact Us</p>
                We encourage you to contact us if you have any questions or concerns regarding the privacy aspects of Our services or would like to make a complaint. You may write us at  info@trudocgroup.com Attention: Privacy Officer.

                 <br /><br /><br /><br />
                <p className="fs-20 text-thm-blue">Changes to this policy</p>
                This Policy is current as of the Effective Date set forth above. We may change this Policy from time to time, so please be sure to check back periodically. We will post any changes to this Policy on this page. If we make any changes to this Policy that materially affect our practices with regard to the
                personal information, we have previously collected from you, we will endeavor to provide you with notice in advance of such change, such as by highlighting the change on our Site, or emailing the email address of record for your account.

























            </PageContent>
            <Footer />
        </>
    )
}

export default memo(PrivacyAndPolicy);