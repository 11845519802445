import React from 'react';
import { Link } from 'react-router-dom';

export default function Sidebar() {
    return (
        <>
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#home" aria-expanded="false"
                            aria-controls="home">
                            <i className="menu-icon mdi mdi-floor-plan"></i>
                            <span className="menu-title">Home</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="home">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"><Link to='/home' className="nav-link"><span className="menu-text">Banner</span></Link></li>
                                <li className="nav-item"><Link to='/testimonials' className="nav-link"><span className="menu-text">Testimonials</span></Link></li>
                            </ul>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#service" aria-expanded="false"
                            aria-controls="service">
                            <i className="menu-icon mdi mdi-floor-plan"></i>
                            <span className="menu-title">Service</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="service">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"><Link to='/service' className="nav-link"><span className="menu-text">Service</span></Link></li>
                                <li className="nav-item"><Link to='/serviceServices' className="nav-link"><span className="menu-text">Service Items</span></Link></li>
                                <li className="nav-item"><Link to='/serviceFeatures' className="nav-link"><span className="menu-text">Features</span></Link></li>
                                <li className="nav-item"><Link to='/serviceBenefits' className="nav-link"><span className="menu-text">Benefits</span></Link></li>
                            </ul>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#subService" aria-expanded="false"
                            aria-controls="subService">
                            <i className="menu-icon mdi mdi-floor-plan"></i>
                            <span className="menu-title">Sub Service</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="subService">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"><Link to='/subService' className="nav-link"><span className="menu-text">SubService</span></Link></li>
                            </ul>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#faq" aria-expanded="false"
                            aria-controls="faq">
                            <i className="menu-icon mdi mdi-floor-plan"></i>
                            <span className="menu-title">FAQ</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="faq">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"><Link to='/fAQCategory' className="nav-link"><span className="menu-text">FAQ Category</span></Link></li>
                                <li className="nav-item"><Link to='/faqsContent' className="nav-link"><span className="menu-text">FAQ</span></Link></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="#health" aria-expanded="false"
                            aria-controls="health">
                            <i className="menu-icon mdi mdi-floor-plan"></i>
                            <span className="menu-title">Health Library</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="health">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"><Link to='/health' className="nav-link"><span className="menu-text">Health Library</span></Link></li>
                                <li className="nav-item"><Link to='/healthcategory-list' className="nav-link"><span className="menu-text">Health Category</span></Link></li>
                            </ul>
                        </div>
                    </li>

                    <li className="nav-item"><Link to='/mediaadmin' className="nav-link"><span className="menu-text">Media</span></Link></li>
                    <li className="nav-item"><Link to='/ourpresence-list' className="nav-link"><span className="menu-text">Our Presence</span></Link></li>
                    <li className="nav-item"><Link to='/careeradmin' className="nav-link"><span className="menu-text">Careers</span></Link></li>
                    <li className="nav-item"><Link to='/contactusadmin' className="nav-link"><span className="menu-text">Contact Us</span></Link></li>
                    <li className="nav-item"><Link to='/setting' className="nav-link"><span className="menu-text">Setting</span></Link></li>

                </ul>
            </nav>
        </>
    )
}
