import React, { Component } from 'react'
import httpHelper from '../../../httpHelper';
import DashboardHeader from '../../Dashboard/DashboardHeader';
import Sidebar from '../../Dashboard/Sidebar';
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from 'suneditor-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CreateSubServiceComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            serviceId: '',
            services: [],
            title: '',
            description: '',
            content: '',
            thumbnail: '',
            image: '',
            isVideo: false,
            videoUrl: '',
            errors: {},
            subServiceUrl:'',
            subServicedata: [
                {
                    Value: 'MentalHealthCare',
                    Name: 'Mental Health Care',
                },
                {
                    Value: 'NutritionCare',
                    Name: 'Nutrition Care',
                },
                {
                    Value: 'PatientManagement',
                    Name: 'Patient Management',
                }
            ]
        }
        this.changeTitleHandler = this.changeTitleHandler.bind(this);
        this.saveOrUpdateSubService = this.saveOrUpdateSubService.bind(this);
    }
    handleValidation(data) {
        let errors = {};
        let formIsValid = true;

        if (data.serviceId == "") {
            formIsValid = false;
            errors["serviceId"] = "Cannot be empty";
        }

        if (data.title == "") {
            formIsValid = false;
            errors["title"] = "Cannot be empty";
        }
        if (data.subServiceUrl == "") {
            formIsValid = false;
            errors["subServiceUrl"] = "Cannot be empty";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    componentDidMount() {

        if (this.state.id === '_add') {
            httpHelper.get('GetListDropDownServices').then(response => {
                console.log(response.data);
                this.setState({
                    services: response.data
                });
            });
            return
        } else {

            httpHelper.get('GetListDropDownServices').then(response => {
                console.log(response.data);
                this.setState({
                    services: response.data
                });
            });

            httpHelper.getById('GetSubServicesById', this.state.id).then((res) => {
                let subService = res.data;
                this.setState({
                    serviceId: subService.serviceId,
                    title: subService.title,
                    description: subService.description,
                    content: subService.content,
                    thumbnail: subService.thumbnail,
                    image: subService.image,
                    isVideo: subService.isVideo,
                    videoUrl: subService.videoUrl,
                    subServiceUrl:subService.subServiceUrl
                });
            });

        }
    }

    saveOrUpdateSubService = (e) => {
        e.preventDefault();
        let subService = {
            serviceId: this.state.serviceId,
            title: this.state.title,
            description: this.state.description,
            content: this.state.content,
            thumbnail: this.state.thumbnail,
            image: this.state.image,
            isVideo: this.state.isVideo,
            videoUrl: this.state.videoUrl,
            subServiceUrl:this.state.subServiceUrl
        };
        console.log('subService => ' + JSON.stringify(subService));

        // step 5
        if (this.state.id === '_add') {
            if (this.handleValidation(subService)) {
                httpHelper.create('/PostSubServices', subService).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/subService');
                    /*setInterval(() => { this.props.history.push('/subService'); }, 1000);*/
                });
            }
        } else {
            if (this.handleValidation(subService)) {
                httpHelper.update('UpdateSubServices', subService, this.state.id).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/subService');
                    /*setInterval(() => { this.props.history.push('/subService'); }, 1000);*/
                });
            }
        }
    }

    changeServiceIdHandler = (event) => {
        this.setState({ serviceId: event.target.value });
    }
    changeSubServiceIdHandler= (event) => {
        this.setState({ subServiceUrl: event.target.value });
    }

    changeTitleHandler = (event) => {
        this.setState({ title: event.target.value });
    }

    //changeDescriptionHandler = (event) => {
    //    this.setState({ description: event.target.value });
    //}

    changeContentHandler = (event) => {
        this.setState({ content: event });
    }
    getFiles(files) {
        if (files.base64 !== "") {
            this.setState({ image: files.base64 })
        } else {
            this.state.vaild = true;
        }
    }
    //changeThumbnailHandler = (event) => {

    //    const formData = new FormData();
    //    const filePath = "Upload\\SubService\\Thumbnail";
    //    formData.append("fileName", event.target.files[0].name);
    //    formData.append("formFile", event.target.files[0]);
    //    formData.append("filePath", filePath);
    //    try {
    //        const res = httpHelper.upload("CommonFileUpload", formData);
    //    } catch (ex) {
    //    }
    //    this.setState({ thumbnail: event.target.files[0].name });

    //}

    changeImageHandler = (event) => {
        const formData = new FormData();
        const filePath = "Upload\\SubService";
        formData.append("fileName", event.target.files[0].name);
        formData.append("formFile", event.target.files[0]);
        formData.append("filePath", filePath);
        try {
            const res = httpHelper.upload("CommonFileUpload", formData);
        } catch (ex) {
        }
        this.setState({ image: event.target.files[0].name });
    }

    changeIsVideoHandler = (event) => {
        this.setState({ isVideo: event.target.checked });
    }

    changeVideoUrlHandler = (event) => {
        this.setState({ videoUrl: event.target.value });
    }



    cancel() {
        this.props.history.push('/subService');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add SubService</h3>
        } else {
            return <h3 className="text-center">Update SubService</h3>
        }
    }


    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Sub Service</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Service: </label>
                                                            <select className="form-control" name="serviceId" value={this.state.serviceId} onChange={this.changeServiceIdHandler}  >
                                                                <option>Select Service</option>
                                                                {this.state.services.map((e, key) => {
                                                                    return <option key={key} value={e.dataValue}>{e.dataText}</option>;
                                                                })}
                                                            </select>
                                                            <span style={{ color: "red" }}>{this.state.errors["serviceId"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control"
                                                                value={this.state.title} onChange={this.changeTitleHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["title"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">                                                      
                                                        <div className="form-group">
                                                            <img width='500' height='200' src={this.state.image} />
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="file" placeholder="Image" name="image" className="form-control"
                                                                onChange={this.changeImageHandler} />                                                         
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Content: </label>
                                                            <input placeholder="VideoUrl" name="videoUrl" className="form-control"
                                                                value={this.state.videoUrl} onChange={this.changeVideoUrlHandler} />
                                                        </div>
                                                        <div className="form-check form-check-flat form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" name="isVideo" className="form-check-input"
                                                                    checked={this.state.isVideo}
                                                                    onChange={this.changeIsVideoHandler} />
                                                                Video
                                                                <i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label> Sub Service Url: </label>
                                                            <select className="form-control" name="subServiceUrl" value={this.state.subServiceUrl} onChange={this.changeSubServiceIdHandler}  >
                                                                <option>Select Sub Service</option>
                                                                {this.state.subServicedata.map((e, key) => {
                                                                    return <option key={key} value={e.Value}>{e.Name}</option>;
                                                                })}
                                                            </select>
                                                            <span style={{ color: "red" }}>{this.state.errors["subServiceUrl"]}</span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Content: </label>
                                                            <SunEditor name="content" height="400" placeholder="Content"
                                                                setOptions={{
                                                                    buttonList: [
                                                                        /*['undo', 'redo', 'font', 'fontSize', 'formatBlock'],*/
                                                                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                                        /*'/', //Line break*/
                                                                        ['hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                                        ['fontColor', 'fontSize', 'font'],
                                                                        /*['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']*/
                                                                    ],
                                                                }}
                                                                setContents={this.state.content} defaultValue={this.state.content} onChange={this.changeContentHandler} />
                                                           
                                                        </div>
                                                    </div>                                                  
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                        </div>
                                                        <div className="col-md-2">
                                                            <button className="btn btn-primary btn-sm" onClick={this.saveOrUpdateSubService} style={{ marginTop: "30px" }}>Save</button>
                                                            <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>
                                                        </div>
                                                        <div className="col-md-5">
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default CreateSubServiceComponent