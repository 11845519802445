import React from "react";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import Timeline from "../components/Timeline";
import { Helmet } from 'react-helmet';

export default function Milestone() {
    return (
        <>
            <Helmet>
                <title>Our Milestones</title>
                <meta name="description" content="With over 33 years of experience in outpatient healthcare services, our Founder and CEO, Raouf Khalil identified a looming shortage of telemedicine services in the region. Given the rapidly aging population, rising health care costs, and the need for accessible medical care, he recognized the opportunity to step in and make a difference." />
                <meta name="theme-color" content="#0778b2" />
            </Helmet>
            <Header transparent={false} />
           <Timeline />
            
            <Footer />
        </>
    );
}
