import React, { Component } from 'react'
import httpHelper from '../../../httpHelper';
import DashboardHeader from '../../Dashboard/DashboardHeader';
import Sidebar from '../../Dashboard/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



class CreateCategoryComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            title: '',
            description: '',
            content: '',
            image: '',
            HealthCategories: [],
        }
        this.changeCategoryHandler = this.changeCategoryHandler.bind(this);
        this.saveOrUpdateHealthCategory = this.saveOrUpdateHealthCategory.bind(this);
    }

    componentDidMount() {

        if (this.state.id === '_add') {

            return
        } else {

            httpHelper.getById('GetHealthCategoryById', this.state.id).then((res) => {
                let home = res.data;
                this.setState({
                    category: home.category,

                });
            });
        }
    }

    saveOrUpdateHealthCategory = (e) => {
        e.preventDefault();
        let health = {
            category: this.state.category,

        };
        // console.log('home => ' + JSON.stringify(home));
        // step 5
        if (this.state.id === '_add') {
            httpHelper.create('/AddHealthCategory', health).then(res => {
                toast("Saved successfully!");
                this.props.history.push('/healthcategory-list');
                /*setInterval(() => { this.props.history.push('/healthcategory-list'); }, 1000);*/
            });
        } else {
            health.id = this.state.id;
            httpHelper.update('UpdateHealthCategory', health, this.state.id).then(res => {
                toast("Saved successfully!");
                this.props.history.push('/healthcategory-list');
                /*setInterval(() => { this.props.history.push('/healthcategory-list'); }, 1000);*/
            });
        }
    }

    changeCategoryHandler = (event) => {
        this.setState({ category: event.target.value });
    }

    cancel() {
        this.props.history.push('/healthcategory-list');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add HealthCategory</h3>
        } else {
            return <h3 className="text-center">Update HealthCategory</h3>
        }
    }


    changeHealthCategoryIdHandler = (event) => {
        this.setState({ healthcategoryid: event.target.value });
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Health Category</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Category: </label>
                                                            <input placeholder="Category" name="category" className="form-control"
                                                                value={this.state.category} onChange={this.changeCategoryHandler} required="required" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5"></div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-primary btn-sm" onClick={this.saveOrUpdateHealthCategory} style={{ marginTop: "30px" }}>Save</button>
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default CreateCategoryComponent