import React, { Component } from 'react'
import httpHelper from '../../../httpHelper';
import DashboardHeader from '../../Dashboard/DashboardHeader';
import Sidebar from '../../Dashboard/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class CreateFAQCategoryComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            name: '',
            ordinal: '',
            disableFormBtn : false,
            errors: {}
        }
        this.changeNameHandler = this.changeNameHandler.bind(this);
        this.saveOrUpdateFAQCategory = this.saveOrUpdateFAQCategory.bind(this);
    }

    componentDidMount() {

        if (this.state.id === '_add') {
            return
        } else {
            httpHelper.getById('GetFaqCategoryById', this.state.id).then((res) => {
                let fAQcategory = res.data;
                this.setState({
                    name: fAQcategory.name,
                    ordinal: fAQcategory.ordinal,
                });
            });
        }
    }
    handleValidation(data) {
        let errors = {};
        let formIsValid = true;

        if (data.name == "") {
            formIsValid = false;
            errors["name"] = "Cannot be empty";
        }
        if (data.ordinal == "") {
            formIsValid = false;
            errors["ordinal"] = "Cannot be empty";
        } 
        this.setState({ errors: errors });
        return formIsValid;
    }
    saveOrUpdateFAQCategory = async (e) => {
        e.preventDefault();
        let fAQcategory = {
            name: this.state.name,
            ordinal: this.state.ordinal
        };
        /*console.log('fAQcategory => ' + JSON.stringify(fAQcategory));*/

        // step 5
        if (this.state.id === '_add') {
            if (this.handleValidation(fAQcategory)) {
                httpHelper.create('/AddFaqCategory', fAQcategory).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/fAQCategory');
                    /*setInterval(() => { this.props.history.push('/fAQCategory'); }, 1000);                   */
                });
            }

        } else {
            try {
                await httpHelper.update('UpdateFaqCategory', fAQcategory, this.state.id).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/fAQCategory');
                    /*setInterval(() => { this.props.history.push('/fAQCategory'); }, 1000);*/
                });
            } catch (error) {
                this.setState({ errors: { ordinal: "Ordinal is already occupied" } });
                this.setState({ disableFormBtn: true });
            }
        }
    }

    changeNameHandler = (event) => {

        this.setState({ name: event.target.value });

    }
    changeOrdinalHandler = async (ordianlValue, id = null) => {
        this.setState({ ordinal: ordianlValue });        
    }

    //getFiles(files) {
    //    if (files.base64 !== "") {
    //        this.setState({ bannerImage: files.base64 })
    //    } else {
    //        this.state.vaild = true;
    //    }
    //}
    cancel() {
        this.props.history.push('/fAQCategory');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add FAQ Category</h3>
        } else {
            return <h3 className="text-center">Update FAQ Category</h3>
        }
    }

    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">FAQ Category</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Name: </label>
                                                            <input placeholder="Name" name="name" className="form-control"
                                                                value={this.state.name} onChange={this.changeNameHandler} required="required" />
                                                            <span style={{ color: "red" }}>{this.state.errors["name"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Ordinal: </label>
                                                            <input placeholder="Ordinal" type="number" name="name" className="form-control"
                                                                value={this.state.ordinal} onChange={(e) => { this.changeOrdinalHandler(e.target.value) }} required="required" />
                                                            <span style={{ color: "red" }}>{this.state.errors["ordinal"]}</span>
                                                        </div>
                                                    </div>
                                                    {/*<div className="col-md-6">*/}
                                                    {/*    <div className="form-group">*/}
                                                    {/*        <label> Attachments: </label>*/}
                                                    {/*        <img width='500' height='200' src={this.state.bannerImage} alt="banner" title="banner" />*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className="form-group">*/}
                                                    {/*        <label> Attachments: </label>*/}
                                                    {/*        */}{/*<input type="file" placeholder="Attachments" name="Attachments" className="form-control"*/}
                                                    {/*        */}{/*    onChange={this.changeAttachmentsHandler} />*/}
                                                    {/*        <FileBase64 placeholder="Attachments" name="attachments" className="form-control"*/}
                                                    {/*            multiple={false}*/}
                                                    {/*            onDone={this.getFiles.bind(this)} />*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button disabled={this.state.disableFormBtn} className="btn btn-primary btn-sm" onClick={this.saveOrUpdateFAQCategory} style={{ marginTop: "30px" }}>Save</button>
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <span style={{ color: "red" }}>{this.state.error}</span>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default CreateFAQCategoryComponent