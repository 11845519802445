import React from "react";

import { InfoWindow, Marker } from "@react-google-maps/api";

export default class MapMarker extends React.Component {


    state = {
        mapMarker: null,
        activeMarker: false,
        selectedPlace: {},
        showingInfoWindow: false
    };

    onMarkerClick = (props, marker) => {
        this.setState({
            activeMarker: true,
            selectedPlace: props,
            showingInfoWindow: true
        });
    }
    onInfoWindowClose = () => {
        this.setState({
            activeMarker: false,
            showingInfoWindow: false,
            mapMarker: null
        }, () => {
            console.log(this.state);
        });
    }

    onLoad = mapMarker => {
        this.setState({
            mapMarker
        });
    };

    render() {
        const { clusterer, markerData } = this.props;
        const { mapMarker } = this.state;
        return (
            <Marker
                clusterer={clusterer}
                onLoad={this.onLoad}
                position={{
                    lat: markerData.lat,
                    lng: markerData.lng
                }}
                icon={{
                    url: markerData.countryFlag,
                    anchor: new window.google.maps.Point(17, 46),
                    scaledSize: new window.google.maps.Size(32, 32)
                }}
                title={markerData.title}
                onClick={() => this.onMarkerClick()}

            >
                {(this.state.showingInfoWindow) ?
                    <InfoWindow
                        position={{
                            lat: markerData.lat,
                            lng: markerData.lng
                        }}
                        marker={this.state.activeMarker}

                        onCloseClick={() => this.onInfoWindowClose()}
                        visible={this.state.showingInfoWindow}
                    >

                        <div className="newMarkUpMap" style={{ height: "150px", width: "350px" }} >
                            <section id="ContactUs">
                                <div className="formMap">
                                    <div className="container p-0">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="mapFormBox">
                                                    <div className="MapOurPresenceHeadingBox align-items-start">
                                                        <img src={process.env.PUBLIC_URL + 'assets/img/place.png'} alt="Map" title="Map" />
                                                        {markerData.countryFlag ? <img src={markerData.countryFlag} className="mapIconflag" width="25px" /> : ''}
                                                        <div className="MapOurPresenceHeadingCountryBox">
                                                            <div className="MapOurPresenceHeadingCountry">
                                                                {markerData.country}
                                                            </div>
                                                            <div className="MapOurPresenceHeading mt-3">
                                                                {markerData.title}
                                                            </div>
                                                            <div className="addressBox mt-0 pt-0 pl-0 p-0 pt-0 pb-0" style={{ backgroundColor: '#fff' }} >
                                                                <div className="mapFormBoxDetails">
                                                                    <div className="mapFormDetailsTitle">
                                                                        Call us:
                                                                    </div>
                                                                    <div className="mapFormDetailsDesc detailsPhoneAndEmail pl-0">
                                                                        <img src={process.env.PUBLIC_URL + 'assets/img/call-new.svg'} className="ourPresenseIconMapPhone" alt="call Us" title="Call Us" />{markerData.phoneNo}
                                                                    </div>
                                                                </div>
                                                                <div className="mapFormBoxDetails">
                                                                    <div className="mapFormDetailsTitle">
                                                                        Email us:
                                                                    </div>
                                                                    <div className="mapFormDetailsDesc detailsPhoneAndEmail pl-0">
                                                                        {markerData.email}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>

                    </InfoWindow>
                    : null
                }
            </Marker>
        );
    }
}