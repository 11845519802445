import React, { Component } from 'react'
import { disable } from 'workbox-navigation-preload';
import HttpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';

class ViewCareerComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            career: {}
        }
    }

    componentDidMount() {
        HttpHelper.getById('GetCareersById', this.state.id).then(res => {
            this.setState({ career: res.data });
        })
    }

    cancel() {
        this.props.history.push('/careers');
    }

    render() {
        return (


            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Career</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> First Name: </label>
                                                            <input placeholder="First Name" name="firstName" className="form-control" readOnly={true}
                                                                value={this.state.career.firstName} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Last Name: </label>
                                                            <input placeholder="Last Name" name="lastName" className="form-control" readOnly={true}
                                                                value={this.state.career.lastName} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Phone Number: </label>
                                                            <input placeholder="Phone Number" name="phoneNumber" className="form-control" readOnly={true}
                                                                value={this.state.career.phoneNumber} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Email: </label>
                                                            <input placeholder="Email Address" name="email" className="form-control"
                                                                value={this.state.career.email} readOnly={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Cover Letter: </label>
                                                            <textarea className="form-control" name="coverLetter" placeholder="Cover Letter"
                                                                value={this.state.career.coverLetter} readOnly={true} style={{ maxHeight: '90px', minHeight: '90px', resize: 'none', padding: '9px', boxSizing: 'border-box', fontSize: '15px' }} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-check form-check-flat form-check-primary">
                                                                <label className="form-check-label">
                                                                    <input type="checkbox" name="attachmentsType" className="form-check-input" value="true"
                                                                        checked={this.state.career.attachmentsType === true} disabled={true} />
                                                                    Curriculum Vitae
                                                                    <i className="input-helper"></i></label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-check form-check-flat form-check-primary">
                                                                <label className="form-check-label">
                                                                    <input type="checkbox" name="attachmentsType" className="form-check-input" value="false"
                                                                        checked={this.state.career.attachmentsType === false} disabled={true} />
                                                                    Recommendation Letter (Optional)
                                                                    <i className="input-helper"></i></label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Attachments: </label>
                                                            <input type="file" placeholder="Attachments" name="Attachments" className="form-control" 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Back</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

export default ViewCareerComponent