import React, { Component } from 'react'
import { disable } from 'workbox-navigation-preload';
import HttpHelper from '../../httpHelper';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Sidebar from '../Dashboard/Sidebar';

class ViewHomeComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            home: {}
        }
    }

    componentDidMount() {
        HttpHelper.getById('GetHomeById', this.state.id).then(res => {
            this.setState({ home: res.data });
            /*console.log(res.data);*/
        })
    }

    cancel() {
        this.props.history.push('/home');
    }

    render() {
        return (

            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Home</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control" readOnly={true}
                                                                value={this.state.home.title} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Thumbnail: </label>
                                                            <img width='500' height='200' src={this.state.home.bannerImage} alt={this.state.home.title} title={this.state.home.title} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Back</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

export default ViewHomeComponent