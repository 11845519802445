import React, { memo, useEffect, useState } from "react";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import HomeSlider from "../components/HomeSlider";
import { Helmet } from 'react-helmet';
import PageTitle from "../components/PageTitle";
import PatientsRightsResponsibilities from "../components/PatientsRightsResponsibilities";
import EntryPointsTeleconsultation from "../components/EntryPointsTeleconsultation";
import TeleconsultationServices from "../components/TeleconsultationServices";
import PageContent from "../components/PageContent";
import Situations from "../components/Situations";
import Accordion from 'react-bootstrap/Accordion';
import ReactHtmlParser from 'react-html-parser';
import HttpHelper from '../httpHelper';
import TextWithImageFullWidth from "../components/TextWithImageFullWidth";




const TeleMedicinesAbuDhabiEn = () => {
    const [articles, setArticles] = useState([]);
    useEffect(() => {
        HttpHelper.get('GetFaqsContentForWhatWeDoEn').then((res) => {
            setArticles(res.data);
        })
    }, []);

    return (
        <>
            <Helmet>
                <title>TeleMedicine for AbuDhabi</title>
                <meta name="description" content="" />
                <meta name="theme-color" content="#0778b2" />
            </Helmet>

            <Header transparent={false} />
            <section id="Pagetitle" className="mt-TitlePge" style={{ backgroundImage: ` url(${process.env.PUBLIC_URL + 'assets/img/TelemedicineforAbudhabi.jpg'})` }}>
                <div class="overlayContent"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="text-white textPageTile">Telemedicine<br /> for Abu Dhabi</h1>
                        </div>
                    </div>
                </div>
            </section>
            { /* <TeleconsultationServices heading="Know more about our Teleconsultation Services" /> */}
            <section id="TeleconsultationServices" className="KnowMoreTeleconsultation">
                <PageContent class="bg-white" >

                    <h2 className="text-ThemeOne heading-Section" color="blue">
                        Know more about our Teleconsultation service
                    </h2>
                    <h4>Our Teleconsultation service includes:</h4>
                    <br />

                    <div className="row">
                        <div className="col-md-6">
                            <ul className="truoc_ul_center">
                                <li className="fs-20"><img src="assets/img/24_7access.jpg" alt="Tick" title="tick" className="teleservices " />24/7 access to virtual consultation with general practitioners</li>
                                <li className="fs-20"><img src="assets/img/cronic-care.svg" alt="Tick" title="tick" className="teleservices " />Triage, diagnosis, medical advice, and follow-up care for acute and chronic care</li>
                                <li className="fs-20"><img src="assets/img/guidance.svg" alt="Tick" title="tick" className="teleservices " />Guidance and recommendation on preventive and self-care</li>
                                <li className="fs-20"><img src="assets/img/video-call.svg" alt="Tick" title="tick" className="teleservices " />Teleconsultation through voice and video calls </li>

                            </ul>
                        </div>
                        <div className="col-md-6">
                            <ul className="truoc_ul_center">

                                <li className="fs-20"><img src="assets/img/prescription.svg" alt="Tick" title="tick" className="teleservices " />Medication prescription, when applicable</li>
                                <li className="fs-20"><img src="assets/img/delivery.svg" alt="Tick" title="tick" className="teleservices " />Medication delivery, where available</li>
                                <li className="fs-20"><img src="assets/img/multilingual.svg" alt="Tick" title="tick" className="teleservices " />Multilingual in-house doctors(English, Arabic, Hindu and Urdu)</li>
                                <li className="fs-20"><img src="assets/img/referral.svg" alt="Tick" title="tick" className="teleservices " />Tele-referral to specialist where applicable and appropriate booking at the nearest provider as appropriate </li>
                            </ul>
                        </div>
                    </div>

                </ PageContent>
            </section>

            {/* }<Situations heading="Situations you can use Teleonsultation for" />*/}

            <section id="OurSection" className="SituationKnowMoreTelemedicineAbudhabi">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="text-ThemeOne heading-Section">Situations you can use Teleconsultation for</h1>
                        </div>
                        <div className="col-md-12 mt-Btn-Section">
                            <div className="row">
                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className=" mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/preventive-care.svg"} className="our-p-img" alt="Preventive" />
                                    </div>
                                    <p className="ParaTextSituation">Preventive care and general medical advice</p>
                                </div>
                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className=" mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/respiratory.svg"} className="our-p-img" alt="Dedication" title="Dedication" />
                                    </div>
                                    <p className="ParaTextSituation">Respiratory </p>
                                </div>
                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="text-center mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/chronic-condition.svg"} className="our-p-img" alt="Compassion" title="Compassion" />
                                    </div>
                                    <p className="ParaTextSituation">Chronic conditions </p>
                                </div>
                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/digestive.svg"} className="our-p-img" alt="Innovation" title="innovation" />
                                    </div>
                                    <p className="ParaTextSituation">Digestive </p>
                                </div>
                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-5">
                                        <img src={process.env.PUBLIC_URL + "assets/img/skin.svg"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <p className="ParaTextSituation">Skin </p>
                                </div>

                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/musculoskeletel.svg"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <p className="ParaTextSituation">Musculoskeletal </p>
                                </div>

                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/genitourinary.svg"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <p className="ParaTextSituation">Genitourinary </p>
                                </div>

                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/eye.svg"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <p className="ParaTextSituation">Eye </p>
                                </div>

                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/systematic.svg"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <p className="ParaTextSituation">Systemic </p>
                                </div>

                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/ear-nose-throat.svg"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <p className="ParaTextSituation">Ear-Nose-Throat </p>
                                </div>

                                <div className="col-md-3 mb-3 m-our-p text-center">
                                    <div className="mb-3">
                                        <img src={process.env.PUBLIC_URL + "assets/img/obstetrics.svg"} className="our-p-img" alt="People Centric" title="People Centric" />
                                    </div>
                                    <p className="ParaTextSituation">Obstetrics/Gynaecology </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <EntryPointsTeleconsultation heading="Situations you can use Teleonsultation for" /> */}

            <section id="PageContent" className='bg-white' style={{ paddingBottom: '75px' }} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>
                                <h2 className="text-ThemeOne heading-Section mb-5" color="blue">
                                    How it works
                                </h2>
                                <img src="assets/img/how-it-worksEn.png" className="img-fluid" />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="paddingBottomServices" >
                {/*<PageTitle className="KnowMoreTeleconsultation"  title="Patients' Rights and </br> Responsibilities" bgImage="assets/img/patient-right-responsibilities.jpeg"  />*/}
                <section id="Pagetitle" class="pageTitleTeleMedicine" style={{ backgroundImage: ` url(${process.env.PUBLIC_URL + 'assets/img/patient-right-responsibilities.jpeg'})` }}>
                    <div class="overlayContent"></div>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h1 class="text-white textPageTile pageTitleTeleMedicineTxtHeading">Patients' Rights and <br /> Responsibilities</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <PageContent class="rr-TitlePge"  >
                    <p className="font24">TruDoc has created a Patient Charter setting out patient rights and responsibilities. You the Patient and/or when appropriate family, in return, have the following responsibilities.</p>

                </ PageContent>

                <PageContent class="bg-white" >
                    <h4 className="text-ThemeOne heading-Section" color="blue" style={{ paddingBottom: '25px' }}>
                        1.1 You have the right to
                    </h4>

                    <ul className="Telemedicinelisttext" style={{ paddingBottom: '15px' }}>


                        <li> Know by name the physician, nurse and other staff members responsible for  your care </li>

                        <li>	Talk openly with your physician about your diagnosis, the treatment prescribed  for you, the prognosis of your illness, and any instruction required 	for the follow up care.</li>

                        <li>	Request that your physician communicates in terminology you may reasonably expect to understand</li>

                        <li>	Have your request courteously received and properly considered as quickly as circumstances permit</li>

                        <li>	Be informed of the reason you are given various tests and treatmnets, and who the person are who give them to you</li>

                        <li>	Be informed of the general nature and inherent risk of any procedure for which you have given your consent</li>

                        <li>	Change your mind about any procedure for which you have given consent, to refuse treatment and to be informed of the medical consequences of this action</li>

                        <li>	Expect your personal privacy to be respected to te fullest extent consistent with the care 	prescribed for you and applicable to UAE laws</li>

                        <li>	Expect all communications and other records pertaining to your care to be kept confidential to the extent 	required by law</li>

                        <li>	Request through your attending physician a second opinion by another physician, to change physicians, or to change hospitals and/or facilities</li>

                        <li>    Participate in ethical discussion that arise in the course of your care</li>

                        <li>	Have impartial access to medical resources indicated for your care without regard to race, nationality, age, gender or disability</li>

                        <li>	Refuse to participate 	in medical training programs or research projects</li>

                        <li>	Care and treatment in a safe environment</li>

                        <li>	Have pain management in a compassionate manner</li>

                        <li>	Be informed about the outcomes  of care, including unanticipated outcomes</li>

                    </ul>

                    <h3 className="text-ThemeOne heading-Section" color="blue" style={{ paddingBottom: '25px' }}>
                        1.2 You in turn have the responsibility to
                    </h3>

                    <ul className="Telemedicinelisttext">
                        <li>   Give cooperation and to follow the care prescribed or recommended for you by your physician, nurse or staff members responsible for your care</li>

                        <li>	Notify your physician or nurse if you do not understand your diagnosis, treatment 	or prognosis</li>

                        <li>	Provide to the healthcare provider, to the best of your knowledge, accurate and complete information about present complaints, past illness, hospitalization, medications and any other matters relating 	to your health</li>

                        <li>   Respect 	the rights and privacy 	of other 	patients and follow institutional policies as posted</li>

                        <li>   To keep appointments, and when you are unable to do so for any reason, to notify the healthcare provider</li>

                        <li>   Accept your actions if you refuse treatment or do not follow the healthcare provider's instructions</li>

                        <li>   Accept financial obligations associated with your care 	</li>

                    </ul>

                </ PageContent>
                <div className="complaintPolicy">
                    <div className="container">


                        <div className="row">
                            <div className="col-md-12">

                                <h2 className="headingComplaintPrimary " >Our Complaint Policy</h2>
                            </div>
                        </div>
                        <p> We appreciate any feedback or concerns you may have, and we have a Complaint Policy in place to guarantee  that issues are addressed openly and honestly.</p>
                        <p> If you have a complaint, please call our toll free number 800 878362 or email us at complaints@trudoc.ae at any time. As a patient you have the option of filling a complaint with TruDoc directly, or alternatively with Health Authority-Abu Dhabi through the Abu Dhabi Government Contact Centre toll free number 800 555.</p>
                        <p>We pledge to resolve all complaints within 5 days and to acknowledge all written complaints within 3 working days. If the nature of complaint prevents us from resolving it within 5 days, we will notify you of the delay. On your request, we will notify you of the activities that will be done in response to your complaint.</p>
                        <p>We fully adhere to Health Authority- Abu Dhabi's standards for patient rights and responsibilities and aim to provide you with the reassurance that your complaint will be acted upon promptly and fairly as per our ComPlaint Policy. </p>



                    </div>
                </div>


            </section>

            <section className="searchFaqSection bg-white">
                <div className="container">
                    <div className="row">
                        <h3 className="text-ThemeOne heading-Section mb-5" color="blue">
                            FAQs
                        </h3>
                        <div className="row">
                            <Accordion defaultActiveKey={['0']}>
                                {articles.map((element, index) => {
                                    return <Accordion.Item eventKey={index} className="accordianFAQ">
                                        <Accordion.Header>{element.questions}</Accordion.Header>
                                        <Accordion.Body className="fs-20 text-thm-blue contentPara">
                                            {ReactHtmlParser(element.answers)}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                })}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
            <section id="BottomHeadingStripText">
                <div className="container">
                    <h3 className="text-ThemeOne1 colorTehemeOne faqHEading" >
                        TruDoc operates in Abu Dhabi as a Health Facility under the License Number MF7305
                    </h3>
                </div>
            </section>
            <Footer />
        </>
    );
}
export default memo(TeleMedicinesAbuDhabiEn);