import React, { Component } from 'react'
import httpHelper from '../../../httpHelper';
import DashboardHeader from '../../Dashboard/DashboardHeader';
import Sidebar from '../../Dashboard/Sidebar';
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from 'suneditor-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CreateServiceComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            title: '',
            description: '',
            content: '',
            thumbnail: '',
            image: '',
            isVideo: false,
            videoUrl: '',
            errors: {}
        }
        this.changeTitleHandler = this.changeTitleHandler.bind(this);
        this.saveOrUpdateService = this.saveOrUpdateService.bind(this);
    }
    handleValidation(data) {
        let errors = {};
        let formIsValid = true;

        if (data.title == "") {
            formIsValid = false;
            errors["title"] = "Cannot be empty";
        }

        if (data.description == "") {
            formIsValid = false;
            errors["description"] = "Cannot be empty";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    componentDidMount() {

        if (this.state.id === '_add') {
            return
        } else {
            httpHelper.getById('GetServicesById', this.state.id).then((res) => {
                let service = res.data;
                this.setState({
                    title: service.title,
                    description: service.description,
                    content: service.content,
                    thumbnail: service.thumbnail,
                    image: service.image,
                    isVideo: service.isVideo,
                    videoUrl: service.videoUrl,
                });
            });
        }
    }

    saveOrUpdateService = (e) => {
        e.preventDefault();
        let service = {
            title: this.state.title,
            description: this.state.description,
            content: this.state.content,
            thumbnail: this.state.thumbnail,
            image: this.state.image,
            isVideo: this.state.isVideo,
            videoUrl: this.state.videoUrl,
        };
        console.log('service => ' + JSON.stringify(service));

        // step 5
        if (this.state.id === '_add') {
            if (this.handleValidation(service)) {
                httpHelper.create('/PostServices', service).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/service');
                    /*setInterval(() => { this.props.history.push('/service'); }, 1000);*/
                });
            }
        } else {
            if (this.handleValidation(service)) {
                httpHelper.update('UpdateServices', service, this.state.id).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/service');
                    /*setInterval(() => { this.props.history.push('/service'); }, 1000);*/
                });
            }
        }
    }

    changeTitleHandler = (event) => {
        this.setState({ title: event.target.value });
    }

    changeDescriptionHandler = (event) => {
        this.setState({ description: event.target.value });
    }

    changeContentHandler = (event) => {
        this.setState({ content: event });
    }

    //changeThumbnailHandler = (event) => {

    //    const formData = new FormData();
    //    const filePath = "Upload\\Service\\Thumbnail";
    //    formData.append("fileName", event.target.files[0].name);
    //    formData.append("formFile", event.target.files[0]);
    //    formData.append("filePath", filePath);
    //    try {
    //        const res = httpHelper.upload("CommonFileUpload", formData);
    //    } catch (ex) {
    //    }
    //    this.setState({ thumbnail: event.target.files[0].name });

    //}

    changeImageHandler = (event) => {
        const formData = new FormData();
        const filePath = "Upload\\Service\\Image";
        formData.append("fileName", event.target.files[0].name);
        formData.append("formFile", event.target.files[0]);
        formData.append("filePath", filePath);
        try {
            const res = httpHelper.upload("CommonFileUpload", formData);
        } catch (ex) {
        }
        this.setState({ image: event.target.files[0].name });
    }

    changeIsVideoHandler = (event) => {
        this.setState({ isVideo: event.target.checked });
    }

    changeVideoUrlHandler = (event) => {
        this.setState({ videoUrl: event.target.value });
    }

    //getFiles(files) {
    //    this.setState({ image: files.base64 })
    //}

    cancel() {
        this.props.history.push('/service');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add Service</h3>
        } else {
            return <h3 className="text-center">Update Service</h3>
        }
    }


    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Service</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Title: </label>
                                                            <input placeholder="Title" name="title" className="form-control"
                                                                value={this.state.title} onChange={this.changeTitleHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["title"]}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Description: </label>
                                                            <input placeholder="Description" name="description" className="form-control"
                                                                value={this.state.description} onChange={this.changeDescriptionHandler} />
                                                            <span style={{ color: "red" }}>{this.state.errors["description"]}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Image: </label>
                                                            <input type="file" placeholder="Image" name="image" className="form-control"
                                                                onChange={this.changeImageHandler} />                                                            
                                                        </div>
                                                        <div className="form-group">
                                                            <label> Video Url: </label>
                                                            <input placeholder="VideoUrl" name="videoUrl" className="form-control"
                                                                value={this.state.videoUrl} onChange={this.changeVideoUrlHandler} />
                                                        </div>
                                                        <div className="form-check form-check-flat form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" name="isVideo" className="form-check-input"
                                                                    checked={this.state.isVideo}
                                                                    onChange={this.changeIsVideoHandler} />
                                                                Video
                                                                <i className="input-helper"></i></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Content: </label>
                                                            <SunEditor name="content" height="400" placeholder="Content" setOptions={{
                                                               buttonList: [
                                                                /*['undo', 'redo', 'font', 'fontSize', 'formatBlock'],*/
                                                                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                                /*'/', //Line break*/
                                                                ['hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                                ['fontColor','fontSize', 'font'],
                                                                /*['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']*/
                                                            ],
                                                            }}
                                                                setContents={this.state.content} defaultValue={this.state.content} onChange={this.changeContentHandler} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-primary btn-sm" onClick={this.saveOrUpdateService} style={{ marginTop: "30px" }}>Save</button>
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div >
        )
    }
}

export default CreateServiceComponent