import React, { Component } from 'react'
import httpHelper from '../../../../httpHelper';
import DashboardHeader from '../../../Dashboard/DashboardHeader';
import Sidebar from '../../../Dashboard/Sidebar';
import SunEditor from 'suneditor-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CreateServiceFeaturesComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            serviceId: '',
            service: [],
            title: '',
            errors: {}

        }
        this.changeTitleHandler = this.changeTitleHandler.bind(this);
        this.saveOrUpdateServiceFeatures = this.saveOrUpdateServiceFeatures.bind(this);
    }
    handleValidation(data) {
        let errors = {};
        let formIsValid = true;

        if (data.serviceId == "") {
            formIsValid = false;
            errors["serviceId"] = "Cannot be empty";
        }

        if (data.title == "") {
            formIsValid = false;
            errors["title"] = "Cannot be empty";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    componentDidMount() {

        if (this.state.id === '_add') {
            httpHelper.get('GetListDropDownServices').then(response => {

                this.setState({
                    service: response.data
                });
            });
            return
        } else {
            httpHelper.get('GetListDropDownServices').then(response => {

                this.setState({
                    service: response.data
                });
            });
            httpHelper.getById('GetServiceFeaturesById', this.state.id).then((res) => {
                let serviceFeatures = res.data;
                this.setState({
                    serviceId: serviceFeatures.serviceId,
                    title: serviceFeatures.title,

                });
            });
        }
    }

    saveOrUpdateServiceFeatures = (e) => {
        e.preventDefault();
        let serviceFeatures = {
            serviceId: this.state.serviceId,
            title: this.state.title,
        };

        if (this.state.id === '_add') {
            if (this.handleValidation(serviceFeatures)) {
                httpHelper.create('/PostServiceFeatures', serviceFeatures).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/serviceFeatures');
                    /*setInterval(() => { this.props.history.push('/serviceFeatures'); }, 1000);*/
                });
            }
        } else {
            if (this.handleValidation(serviceFeatures)) {
                httpHelper.update('UpdateServiceFeatures', serviceFeatures, this.state.id).then(res => {
                    toast("Saved successfully!");
                    this.props.history.push('/serviceFeatures');
                    /*setInterval(() => { this.props.history.push('/serviceFeatures'); }, 1000);*/
                });
            }
        }
    }

    changeServiceIdHandler = (event) => {
        this.setState({ serviceId: event.target.value });
    }

    changeTitleHandler = (event) => {
        this.setState({ title: event.target.value });
    }

    //changeTitleHandler = (event) => {

    //    this.setState({ title: event });

    //}

    cancel() {
        this.props.history.push('/serviceFeatures');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Add ServiceFeatures</h3>
        } else {
            return <h3 className="text-center">Update ServiceFeatures</h3>
        }
    }


    render() {
        return (
            <div>
                <DashboardHeader />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 class="card-title">Service Features</h4>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Description: </label>
                                                            <select className="form-control" name="serviceId" value={this.state.serviceId} onChange={this.changeServiceIdHandler}  >
                                                                <option>Select Service</option>
                                                                {this.state.service.map((e, key) => {
                                                                    return <option key={key} value={e.dataValue}>{e.dataText}</option>;
                                                                })}
                                                            </select>
                                                            <span style={{ color: "red" }}>{this.state.errors["serviceId"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> Content: </label>
                                                            <input placeholder="Title" name="title" className="form-control"
                                                                value={this.state.title} onChange={this.changeTitleHandler} />
                                                            {/*<SunEditor name="content" height="400" placeholder="Content" setOptions={{*/}
                                                            {/*    buttonList: [*/}
                                                            {/*        /*['undo', 'redo', 'font', 'fontSize', 'formatBlock'],*/}
                                                            {/*    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],*/}
                                                            {/*    /*'/', //Line break */}
                                                            {/*    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],*/}
                                                            {/*        /*['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']*/}
                                                            {/*    ],*/}
                                                            {/*}}*/}
                                                            {/*    setContents={this.state.title} defaultValue={this.state.title} onChange={this.changeTitleHandler} />*/}
                                                            {/*<span style={{ color: "red" }}>{this.state.errors["title"]}</span>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-primary btn-sm" onClick={this.saveOrUpdateServiceFeatures} style={{ marginTop: "30px" }}>Save</button>
                                                        <button className="btn btn-danger btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px", marginTop: "30px" }}>Cancel</button>
                                                    </div>
                                                    <div className="col-md-5">
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-right" />
            </div>
        )
    }
}

export default CreateServiceFeaturesComponent