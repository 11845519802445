import React, { Component, memo, useEffect } from "react";
import Slider from "react-slick";
import httpHelper from '../httpHelper';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export class Testimonial extends Component {

    constructor(props) {
        super(props)
        this.state = {
            title: '',
            Content: '',
            Name: '',
            Data: []
        }
    }

    componentDidMount() {
        httpHelper.get('getlisttestimonials').then(response => {
            this.setState({
                Data: response.data
            });
        });
    }

    render() {
        return (
            <section id="Testimonial">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ms-3">
                            <h2 className="heading">
                                {this.props.heading}
                            </h2>
                        </div>
                        <div className="col-md-12 mt-3">
                            <Slider {...settings}>

                                {this.state.Data.map((e) => {
                                    return (
                                        <div key={e.name} className="col-md-6 p-3">
                                            <div className="sliderTestimonial">
                                                <img src={process.env.PUBLIC_URL + "assets/img/block.png"} className="blockImg" alt={e.name} title={e.name} />
                                                <p>
                                                    {ReactHtmlParser(e.content)}
                                                </p>
                                                <div className="name">
                                                    {e.name}, {e.age}
                                                </div>
                                            </div>
                                        </div>)
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default memo(Testimonial);