import React, { memo } from "react";
import { Parallax } from 'react-scroll-parallax';

const Always = (props) => {
    return (
        <section id="always" className={`${props.className === 'white' ? 'bg-white' : ''}`}>
            <div className="row">
                <div className="col-md-12">
                    <Parallax
                        speed={5}
                        className="parallaxEffectAlwaysWithUSParent"
                    >
                        <img src={process.env.PUBLIC_URL + "assets/img/AlwayswithyouNewImage.png"} alt="Always With you" title="Always With you" className="mb--56 res-mb--56 img-fluid transition2s" />
                    </Parallax>
                </div>
            </div>
        </section>
    );
}

export default memo(Always);