import React from "react";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import PageTitle from "../components/PageTitle";
import SubServiceContent from "../components/SubServiceContent";
import SubServiceOffered from "../components/SubServiceOffered";
export default function VirtualClinic() {
    return (
        <>
            <Header transparent={false} />
            <PageTitle title="Nutrition Care" subtitle="Helping you eat well, feel good and look great" bgImage="assets/img/bg-nutricare.png" />
           
            
            <SubServiceContent
                main_heading="You can schedule a phone or video consultation with our dietitians to receive:"
                image1="assets/img/nutri_img1.png"
                image2="assets/img/nutri_img2.png"
                image3="assets/img/nutri_img3.png"
                heading1="Nutrition counseling"
                heading2="Medical conditions management program"
                heading3="Weight management program"
                para1="Our dietitians identify individual nutrition needs and goals, discuss ways to meet those goals, and agree on the next steps
                           "
                para2="Our dietitians provide medical nutrition therapy for acute and chronic diseases which include but are not limited to irritable bowel syndrome (IBS), food intolerance, diabetes, and high blood
                                pressure"
                para3="Our dietitians provide customized plans including weight loss techniques, dietary changes, meal plans, exercise programs, and behavior
                                modifications to achieve a healthy weight. They also provide education and knowledge on how to make appropriate food choices in any situation"
            />


            <SubServiceOffered
                right_image1="assets/img/offer-right-img-1.png"            />
            

           
            <Footer />
        </>
    );
}
