import React, { memo } from "react";
import BoardMembers from "../components/BoardMembers";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import PageTitle from "../components/PageTitle";
import { Helmet } from 'react-helmet';

const ContactUsPage = () => {

    return (
        <>
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="With TruDoc, individuals can get instant access to 24x7 reliable, convenient, and affordable health care. " />
                <meta name="theme-color" content="#0778b2" />
            </Helmet>
            <Header transparent={false} />
            <PageTitle title="Contact Us" bgImage="assets/img/contact-us.png" />
            <section id="ContactUs" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'assets/img/map-ContactUs.png'})` }}>
                <div className="formMap">
                    <div className="container mapBoxDetailContact">
                        <div className="row">
                            <div className="col-md-5 p-ContactMapBox">
                                <div className="mapFormBox p-ContactMap">
                                    <div className="mapFormBoxDetails">
                                        <div className="mapFormDetailsTitle textThemeOne" style={{ width: `auto`}} >
                                            24x7 Toll-free:
                                        </div>
                                        <div className="mapFormDetailsDesc textThemeTwo">
                                            800 878362
                                        </div>
                                    </div>

                                    <div className="mapFormBoxDetails">
                                        <div className="mapFormDetailsTitle textThemeOne">
                                            Board Line:
                                        </div>
                                        <div className="mapFormDetailsDesc textThemeTwo">
                                            +971 4396 1415
                                        </div>
                                    </div>


                                    <div className="mapFormBoxDetails">
                                        <div className="mapFormDetailsTitle textThemeOne">
                                            Email us:
                                        </div>
                                        {sessionStorage.getItem("SelectedCountry") == "UAE" ? <div className="mapFormDetailsDesc textThemeTwo">
                                            info@trudoc.ae
                                        </div>
                                            :
                                            <div className="mapFormDetailsDesc textThemeTwo">
                                                info@trudocgroup.com
                                            </div>
                                        }

                                    </div>
                                    <div className="addressBox">
                                        <p>
                                            Visit us:
                                        </p>

                                        Office 701, Bay Square, Building 1, Business Bay, Dubai, UAE
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContactForm ContainerId="ContactForm" />
            <Footer />
        </>
    )
}

export default memo(ContactUsPage);